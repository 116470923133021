import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';

import { convertToBytes, MAX_STORAGE_SIZE } from 'utils';
import { withTranslation, withAccount } from 'components/hoc';
import { dashboardSlice } from 'rootStateHandler/selectors';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import {
  getNotificationsThunk,
  dashboardSummaryThunk,
} from 'components/Dashboard/thunks';
import Grids from './Elements/Grids';
import styles from './dashboard.module.css';
import { setMsgInfo } from 'actions';

const Dashboard = (props) => {
  const { totalFileSize: totalStorageSize, tr, 
    willSubscriptionEndInTwoWeeks } = props;

  
  const { notifications, dashboard_summary, loading } = useSelector(dashboardSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    getStorageSpace();
  }, [totalStorageSize]);

  const getStorageSpace = () => {
    const currentUsageBytes = convertToBytes(totalStorageSize);
    if (currentUsageBytes >= MAX_STORAGE_SIZE) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: [tr(`storage exceeded 100 mb. please clean up and remove files.`)],
        }),
      );
    }
  };

  // 2 weeks notification warning
  useEffect(() => {
    // Check if payment banner has been shown in current session
    const hasShownPaymentBanner = localStorage.getItem('paymentBannerShown');

    if (!hasShownPaymentBanner && willSubscriptionEndInTwoWeeks) {
      // Note: msg array cannot be empty for the notification component to show
      // This is a placeholder message that won't be displayed when showPaymentBanner is true
      console.log('trying to show banner');
      dispatch(
        setMsgInfo({
          showPaymentBanner: true,
          msg: [tr('_placeholder_msg_')], // Required non-empty message, won't be displayed
        }),
      );
      // Mark banner as shown for this session, wont be shown again till user logs out
      localStorage.setItem('paymentBannerShown', 'true');
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNotificationsThunk());
    dispatch(dashboardSummaryThunk());
  }, [dashboard_summary?.user_space]);

  return (
    <>
      {loading || !Boolean(dashboard_summary?.user_space) ? (
        <div className={styles['lottie_loader']}>
          <Lottie loop autoplay animationData={animatedLoading} />
        </div>
      ) : (
        <div>
          <Grids
            storageCounter={totalStorageSize}
            dashboard_summary={dashboard_summary}
            notifications={notifications}
          />
        </div>
      )}
    </>
  );
};

export default compose(withFileSize, withAccount, withTranslation)(Dashboard);
