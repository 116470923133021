export const eng = {
  'create person, person group and assign a space group':
    'Create person, person group and assign a space group',
  'persons are central in your workspace - the members of your family or household as well as relatives and friends. therefore start to create the persons that you need. group them as you like as person groups. a special group is the one that you assign to your space:all financial data on the dashboard refer to that group. and on the dashboard card "space group" you even see the pictures of its members.':
    'Persons are central in your workspace - the members of your family or household as well as relatives and friends. Therefore start to create the persons that you need. Group them as you like as person groups. A special group is the one that you assign to your space:All financial data on the dashboard refer to that group. And on the Dashboard card "Space Group" you even see the pictures of its members.',

  'propose creation': 'Propose creation',

  'change username': 'Change Username',
  'are you sure you want to change username?':
    'Are you sure you want to change username?',
  'your username has been changed': 'Your username has been changed',
  'are you sure you want to delete?': 'Are you sure you want to delete?',

  automobile: 'Automobile',
  'auto mobile': 'Auto mobile',

  'means of transport category': 'Means of Transport Category',
  'means of transport': 'Means of Transport',
  brand: 'Brand',
  'auto brand': 'Auto Brand',
  'choose brand': 'Choose Brand',
  'model series': 'Model series',
  'model series type': 'Model series type',
  'enter model series type': 'Enter model series type',
  'year of manufacture': 'Year of manufacture',
  'manufacture year': 'Manufacture year',
  'choose manufacture year': 'Choose manufacture year',
  'type (model spec.)': 'Type (model spec.)',
  'construction year': 'Construction year',
  color: 'Color',
  'choose color': 'Choose color',
  'motor color': 'Motor color',
  'energy source': 'Energy source',
  'choose energy source': 'Choose energy source',
  'fuel or energy source': 'Fuel or Energy Source',
  'max speed': 'Max speed',
  'maximum speed': 'Maximum speed',
  'enter maximum speed': 'Enter maximum speed',
  measurement: 'Measurement',
  'choose measurement unit': 'Choose measurement unit',
  power: 'Power',
  'rated power': 'Rated Power',
  'rated power in kw': 'Rated Power in kW',
  'cubic capacity': 'Cubic capacity',
  'cubic capacity in cm³': 'Cubic capacity in cm³',
  'no of doors': 'No of doors',
  'number of doors': 'Number of doors',
  'enter number of doors': 'Enter number of doors',
  'no of seats': 'No of seats',
  'number of seats': 'Number of seats',
  'enter number of seats': 'Enter number of seats',
  'vehicle identification number': 'Vehicle identification number',
  'initial reg date': 'Initial reg date',
  'initial registration date': 'Initial registration date',

  'auto registration': 'Auto registration',
  'registration org': 'Registration org',
  'licence plate': 'Licence plate',
  'registration organization': 'Registration Organization',
  'select registration organization': 'Select registration organization',
  'enter licence plate': 'Enter licence plate',
  'select automobile': 'Select Automobile',
  'select person': 'Select Person',

  'file uploaded successfully': 'File uploaded successfully',
  'error uploading file. please try again.':
    'Error uploading file. Please try again.',
  'error downloading template, please try again later':
    'Error downloading template, Please try again later',
  'please select a file to upload': 'Please select a file to upload',
  'file size exceeds': 'File size exceeds',
  'mb. please choose a smaller file.': 'MB. Please choose a smaller file.',
  'failed to upload data': 'Failed to upload data',
  'download template': 'Download Template',
  'upload income data': 'Upload Income Data',
  'upload expense data': 'Upload Expense Data',
  'upload data': 'Upload Data',
  'uploading...': 'Uploading...',
  'click to upload': 'Click to upload',
  loss: 'Loss',
  profit: 'Profit',
  'offset account': 'Offset Account',
  'actual value': 'Actual value',
  'actual value sc': 'Actual value SC',
  'rec op': 'Rec op',
  'transfer date': 'Transfer date',
  'org country': 'Org Country',
  'finance account': 'Finance Account',
  'item reference id': 'Item Reference ID',
  'financial category': 'Financial Category',
  'document link': 'Document Link',
  'choose resident': 'Choose Resident',
  'choose address': 'Choose Address',
  address: ' Address',

  'yes!': 'Yes!',
  'deleting!': 'Deleting!',
  'please wait...': 'Please wait...',
  'deleted!': 'Deleted!',
  'data has been deleted!': 'Data has been deleted!',
  'data could not be deleted': 'Data could not be deleted',
  'oops!': 'Oops!',
  'description of the filter': 'Description of the filter',
  'save criteria': 'Save criteria',
  '--select favorite--': '--Select favorite--',
  'start date can not be younger than end date':
    'Start date can not be younger than end date',
  'start or End month should not be less than 01 or greater than 12':
    'Start or End month should not be less than 01 or greater than 12',
  'start date can not be younger than end date':
    'Start date can not be younger than end date',
  'start or end year should not be younger than 9999 or older than 1900':
    'Start or End year should not be younger than 9999 or older than 1900',

  'for subscription of': 'For subscription of',
  'month(s)': 'month(s)',
  'you will be charged at': 'you will be charged at',
  'per month': 'per month',
  'delete?': 'Delete?',
  'you have not selected any item to delete':
    'You have not selected any item to delete',
  'you have not selected any item to view': 'You have not selected any item to view',
  'please fill all fields.': 'Please fill all fields.',
  'you have not select any member to approve.':
    'You have not select any member to approve.',
  'You have not select any member to decline.':
    'You have not select any member to decline.',

  'choose contract': 'Choose contract',
  'transaction partner indicator': 'Transaction partner indicator',
  'transaction partner': 'Transaction partner',
  reverse: 'Reverse',
  membership: 'Membership',

  'organisation function': 'Organisation function',
  'organisation proposal created and will be review':
    'Organisation proposal created and will be review',
  'org function assignment added successfully':
    'Org Function Assignment added successfully',
  'org function assignment updated successfully':
    'Org Function Assignment updated successfully',
  'org function proposal added successfully':
    'Org Function proposal added successfully',
  'org function proposal updated successfully':
    'Org Function proposal updated successfully',
  'organization function is required': 'Organization function is required',
  'school type is required': 'School type is required',
  'religion is required': 'Religion is required',
  'bic is required': 'BIC is required',
  'contract created successfully, you can proceed to add contract items':
    'Contract created successfully, you can proceed to add contract items',
  'contract updated successfully': 'Contract updated successfully',

  'add or create your favourite organizations':
    'Add or create your favourite organizations',
  'organizations are (like persons) central in your workspace – the ones you worked for or currently do business with. many organizations (for germany) exist already in our database. search and add a selection as your favourites. once done you can use these anywhere in your workspace. you can also create new organizations, if not yet existent.':
    'Organizations are (like persons) central in your workspace – the ones you worked for or currently do business with. Many organizations (for Germany) exist already in our Database. Search and add a selection as your favourites. Once done you can use these anywhere in your workspace. You can also create new organizations, if not yet existent.',

  'upload and administer documents': 'Upload and administer documents',
  'passports, birth certificates, diploma etc. – documents are important. under the main menu option documents you can click on the upload area in the center and chose the documents from your drive. use the filter on the left or the full-text search bar on top and review the document details on the right. edit, view or delete each document individually.':
    'Passports, birth certificates, diploma etc. – documents are important. Under the main menu option Documents you can click on the upload area in the center and chose the documents from your drive. Use the filter on the left or the full-text search bar on top and review the document details on the right. Edit, view or delete each document individually.',

  'e-mailbox and attachment rules': 'E-Mailbox and attachment rules',
  'for each space admincat provides you with an own space email address. send and receive emails normally, mark them as read, print or export them. you can also assign them to data areas (finance, mobility, etc.) if you like to use that for filtering and sorting. and if you define attachment rules,incoming attachments are stored in the document area automatically, enriched with data according to the individual rules.':
    'For each space AdminCat provides you with an own space email address. Send and receive emails normally, mark them as read, print or export them. You can also assign them to data areas (Finance, Mobility, etc.) if you like to use that for filtering and sorting. And if you define attachment rules,incoming attachments are stored in the document area automatically, enriched with data according to the individual rules.',

  contracts: 'Contracts',
  'a contract is the foundation for financial transactions – and a special type of document. here you can enter your contract details, link it to the involved persons and organizations as well as to a underlying document, that was saved in the documents area.':
    'A contract is the foundation for financial transactions – and a special type of document. Here you can enter your contract details, link it to the involved persons and organizations as well as to a underlying document, that was saved in the documents area.',

  'the dashboard': 'The Dashboard',
  'displays financial key figures and charts for your space – according to your space group and your chosen space currency. it also informs about storage space usage, number of stored documents, unread emails and important notifications concerning documents and contracts.':
    'Displays financial key figures and charts for your space – according to your space group and your chosen space currency. It also informs about Storage Space usage, number of stored documents, unread emails and important notifications concerning documents and contracts.',

  'financial transactions in 53 currencies':
    'Financial transactions in 53 currencies',
  'post your income and consumption transactions like a professional using a double-entry accounting with a variety of payment sources. foreign currencies get translated into your central workspace currency using a daily updated exchange rate. and if a posting is incorrect, just reverse it again whenever you like.':
    'Post your income and consumption transactions like a professional using a double-entry accounting with a variety of payment sources. Foreign currencies get translated into your central workspace currency using a daily updated exchange rate. And if a posting is incorrect, just reverse it again whenever you like.',

  'missing values -> propose new entries': 'Missing values -> Propose new entries',
  'the list of entry options is not complete in certain fields? well, there is a way how you can help us to improve this quickly: just propose a new entry and we will add the entry after a short review period.':
    'The list of entry options is not complete in certain fields? Well, there is a way how you can help us to improve this quickly: Just propose a new entry and we will add the entry after a short review period.',

  'select and make subscription': 'Select and make subscription',
  'we are flexible, just pay for the months you want':
    'We are flexible, just pay for the months you want',
  'you save 21% with this option. choose the number of periods':
    'You save 21% with this option. Choose the number of periods',
  monthly: 'Monthly',
  yearly: 'Yearly',
  'pay now': 'Pay Now',
  'select month(s)': 'Select Month(s)',
  'select year(s)': 'Select Year(s)',
  'select duration': 'Select duration',
  'your payment choice:': 'Your payment choice:',
  month: 'Month',
  year: 'Year',
  months: 'Months',
  years: 'Years',
  'transaction successful': 'Transaction Successful',
  'the transaction was successful, you will be redirected back':
    'The transaction was successful, you will be redirected back',

  'your payment has been initiated, you will be redirected to paypal to complete this payment':
    'Your payment has been initiated, you will be redirected to Paypal to complete this payment',

  'all entries': 'All Entries',
  'issuing person': 'Issuing Person',
  'issuing date': 'Issuing Date',
  'reference month': 'Reference Month',
  'reference year': 'Reference Year',
  'valid range': 'Valid Range',
  'end date': 'End Date',
  'first name': 'First Name',
  'last name': 'Last Name',
  'other names': 'Other Names',
  'birth place': 'Birth Place',
  'birth country': 'Birth Country',
  'death date': 'Death Date',
  'ethnic group': 'Ethnic Group',
  sex: 'Sex',
  'marital status': 'Marital Status',
  'marital status last change': 'Marital Status Last Change',
  religion: 'Religious affiliation',
  'eye color': 'Eye Color',
  'height info': 'Height Info',
  height: 'Height',
  'height unit': 'Height Unit',
  unit: 'Unit',
  cancel: 'Cancel',
  submit: 'Submit',
  name: 'Name',
  'contact us': 'Contact us',
  title: 'Title',
  'birth date': 'Birth Date',
  country: 'Country',
  'related person': 'Related Person',
  state: 'State',
  'city/town': 'City/Town',
  'local municipality': 'Local Municipality',
  'postal area': 'Postal Area',
  'post area': 'Postal Area',
  'building name': 'Building Name',
  'address type': 'Address Type',
  'address type id': 'Addr. Type ID',
  'street direction': 'Street Direction',
  'street name': 'Street Name',
  'street number': 'Street Number',
  'street no': 'Street No',
  'street number suffix': 'Street Number Suffix',
  'street no suffix': 'Street No Suffix',
  'principal indicator': 'Principal Indicator',
  'validity period': 'Validity Period',
  'view/edit/delete': 'View/Edit/Delete',
  'the total size of the selected files exceeds the maximum allowed size of':
    'The total size of the selected files exceeds the maximum allowed size of',

  'the total size of the selected files exceeds the maximum allowed size of':
    'Die ausgewählten Dateien überschreiten die maximal zulässige Größe von',

  'one or more files exceed the maximum allowed file size of':
    'One or more files exceed the maximum allowed file size of',

  '1 year': '1 Year',
  '3 years': '3 Years',
  '5 years': '5 Years',
  '10 years': '10 Years',
  'all time': 'All Time',

  'your session has expired. if you wish, please log in again.':
    'Your session has expired. If you wish, please log in again.',

  payments: 'Payments',
  'make payment': 'Make Payment',
  'service paid until': 'Service paid until',
  'payment date': 'Payment date',

  subscribe: 'Subscribe',

  'valid on': 'Valid on',

  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
  prev: 'Prev',
  next: 'Next',
  add: 'Add',
  change: 'Change',
  'pdf files': 'PDF files',
  'relation part': 'Relation Part',
  'relation to': 'Relation To',
  'valid from': 'Valid From',
  'valid to': 'Valid To',
  'relationship period': 'Relationship Period',
  'contact type': 'Contact Type',
  'country telephone code': 'Country Telephone Code',
  'area telephone code': 'Area Telephone Code',
  'telephone number': 'Telephone Number',
  active: 'Active',
  deactive: 'Deactive',
  address: 'Address',
  email: 'Email',
  cockpit: 'Cockpit',
  'fill-out': 'Fill-Out',
  overview: 'Overview',
  upload: 'Upload',
  filter: 'Filter',
  'filter form': 'Filter Form',
  'issuing space': 'Issuing Space',
  'choose issung space': 'Choose Issung Space',
  headers: 'Headers',
  'form description': 'Form Description',
  status: 'Status',
  'upload date': 'Upload Date',
  'email address': 'Email Address',
  'change password': 'Change Password',
  "it's a good idea that you choose a more secure password":
    "It's a good idea that you choose a more secure password",
  'personal spaces': 'Personal Spaces',
  'you are the administrator -': 'You are the Administrator -',
  'you are the administrator - change': 'You are the Administrator - Change',
  'delete account': 'Delete Account',
  'data storage': 'Data Storage',
  'payment methods': 'Payment Methods',
  'on admincat cloud': 'On AdminCat Cloud',
  locally: 'Locally',
  users: 'Users',
  'user type': 'User Type',
  defaults: 'Defaults',
  'choose file': 'Choose File',
  language: 'Language',
  'decimal notation': 'Decimal Notation',
  'date format': 'Date Format',
  'time format': 'Time Format',
  timezone: 'Timezone',
  username: 'Username',
  'control room': 'Control Room',
  forms: 'Forms',
  settings: 'Settings',
  admin: 'Admin',
  approved: 'Approved',
  pending: 'Pending',
  reject: 'Reject',
  declined: 'Declined',
  'approved users': 'Approved Users',
  space: 'Space',
  'approved date': 'Approved Date',
  browse: 'Browse',
  save: 'Save',
  'organization user': 'Organization User',
  fields: 'Fields',
  profile: 'Profile',
  'user terms': 'User Terms',
  'privacy policy': 'Privacy Policy',
  message: 'Message',
  'need help?': 'Need help?',
  'sign out': 'Sign out',
  'choose title': 'Choose Title',
  'choose birth country': 'Choose Birth Country',
  'choose ethnic group': 'Choose Ethnic Group',
  'choose sex': 'Choose Sex',
  'choose marital status': 'Choose Marital Status',
  'choose religion': 'Choose Religious affiliation',
  'choose eye color': 'Choose Eye Color',
  'choose height unit': 'Choose Height Unit',
  'choose animal': 'Choose Animal',
  'relation form': 'Relation Form',
  'address form': 'Address Form',
  'residence form': 'Residence Form',
  'contact form': 'Contact Form',
  'choose relation part': 'Choose Relation Part',
  'choose relation': 'Choose Relation',
  'choose country': 'Choose Country',
  'choose address type': 'Choose Address Type',
  'choose street direction': 'Choose Street Direction',
  'choose contact type': 'Choose Contact Type',
  'choose country telephone code': 'Choose Country Telephone Code',
  resident: 'Resident',
  residence: 'Residence',
  search: 'Search',
  'my workspaces': 'My Workspaces',
  'pending user requests': 'Pending User Requests',
  'declined users': 'Declined Users',
  'register date': 'Register Date',
  'declined date': 'Declined Date',
  'form upload': 'Form Upload',
  description: 'Description',
  'own sign': 'Own Sign',
  creator: 'Creator',
  ward: 'Ward',
  'form category': 'Form Category',
  'choose form category': 'Choose Form Category',
  'choose language': 'Choose Language',
  'pdf file(fill-able)': 'PDF file(fill-able)',
  'expenditure for this month': 'Expenditure for this month',
  'new successful transaction': 'New successful transaction...',
  draft: 'Draft',
  released: 'Released',
  'archor persons': 'Archor Persons',
  requester: 'Requester',
  'ward in question': 'Ward in question',
  person: 'Person',
  'person groups': 'Person groups',
  'anchor persons': 'Anchor Persons',
  'pending users': 'Pending Users',
  'comment category': 'Comment category',
  'enter description here...': 'Enter description here...',
  'you may contact me via email for this subject':
    'You may contact me via email for this subject',
  'upload a screenshot or explanatory documents':
    'Upload a screenshot or explanatory documents',
  'wish type (please add details in description)':
    'Wish type (please add details in description)',
  'choose wish type': 'Choose wish type',
  'valid period': 'Valid Period',
  category: 'Category',
  position: 'Position',
  'field name': 'Field Name',
  'field type': 'Field Type',
  configured: 'Configured',
  clear: 'Clear',
  'invalid/valid': 'Invalid/Valid',
  type: 'Type',
  'join description': 'Join Description',
  'main area': 'Main Area',
  'criteria sets': 'Criteria Sets',
  'form usage': 'Form Usage',
  'join creator': 'Join Creator',
  id: 'ID',
  create: 'Create',
  'choose comment category': 'Choose comment category',
  citizen: 'Citizen',
  nationality: 'Nationality',
  'send message': 'Send Message',
  'choose nationality': 'Choose Nationality',
  'citizenship information': 'Citizenship Information',
  citizenship: 'Citizenship',
  'citizenship view': 'Citizenship View',
  'citizenship form': 'Citizenship Form',
  'my documents': 'My Documents',
  'reference year': 'Reference Year',
  year: 'Year',
  period: 'Month',
  'issue date': 'Issue Date',
  'issuing organisation': 'Issuing Organisation',
  'issuing org': 'Issuing Org',
  'document type': 'Document Type',
  'concerned person': 'Concerned Person',
  'choose concerned person': 'Choose Concerned Person',
  'document form': 'Document Form',
  'document view': 'Document View',
  'reference month': 'Reference Month',
  file: 'File',
  home: 'Home',
  login: 'Login',
  register: 'Register',
  'register now': 'Register Now',
  'admincat login': 'AdminCat Login',
  'your username': 'Your Username',
  'your password': 'Your Password',
  'forgot password?': 'Forgot Password?',
  'sign in': 'Sign In',
  'admincat registration': 'AdminCat Registration',
  'private user': 'Private User',
  password: 'Password',
  'join personal workspace': 'Join Personal Workspace',
  'create personal workspace': 'Create Personal Workspace',
  'join workspace': 'Join Workspace',
  'create workspace': 'Create Workspace',
  'i agree with the terms and conditions': 'I Agree With the Terms and Conditions',
  'sign up': 'Sign Up',
  'have an account?': 'Have an account?',
  'log in': 'Log in',
  personal: 'Personal',
  health: 'Health',
  finance: 'Finance',
  contract: 'Contract',
  'contract item': 'Contract Item',
  contact: 'Contact',
  relation: 'Relation',
  'relation added successfully': 'Relation added successfully',
  'relation updated successfully': 'Relation updated successfully',
  'favourite organisation added successfully':
    'Favourite Organisation added successfully',
  'contract item added successfully': 'Contract Item added successfully',
  'contract item updated successfully': 'Contract item updated successfully',

  education: 'Education',
  taxes: 'Taxes',
  'house and rent': 'House and Rent',
  'house & rent': 'House & Rent',
  insurance: 'Insurance',
  'sorry, you already sent 600 mails this month.':
    'Sorry, you already sent 600 mails this month.',
  'sorry, you can only propose 5 entries a month!':
    'Sorry, you can only propose 5 entries a month!',
  'space admin change requested': 'Space Admin Change Requested',
  'request space admin change': 'Request Space Admin Change',
  'your subscription ends today': 'Your subscription ends today',
  'all rights reserved': 'All rights reserved',
  work: 'Work',
  'favourite org': 'Favourite Org',
  mobility: 'Mobility',
  'personal information': 'Personal Information',
  'personal form': 'Personal Form',
  'personal view': 'Personal View',
  'relation information': 'Relation Information',
  'address information': 'Address Information',
  'residence information': 'Residence Information',
  'contact information': 'Contact Information',
  'upload documents': 'Upload Documents',
  'documents list': 'Documents List',
  'issuing date': 'Issuing Date',
  area: 'Area',
  copyright: 'Copyright ',
  'an innovative document management tool': 'An Innovative Document Management Tool',
  ind: 'Individual access',
  and: 'Common account (dependent authorization)',
  or: 'Common account (independent auth.)',
  checkacc: 'Checking account',
  instsav: 'Instant access savings account',
  fixeddep: 'Fixed-deposit account',
  creditacc: 'Credit card account',
  savings: 'Savings account',
  depot: 'Custodianship account',
  default: 'Default',
  email: 'Email',
  'delete marked': 'Delete marked',
  'bank account': 'Bank account',
  'account type': 'Account type',
  'select account type': 'Select account type',
  organisation: 'Organisation',
  'account number': 'Account number',
  'account no': 'Account No',
  'access type': 'Access Type',
  'select access type': 'Select access type',
  'bic (swift code)': 'BIC (Swift code)',
  bank: 'Bank',
  'is active': 'is active',
  'reference checking account': 'Reference Checking Account',
  'choose reference checking account': 'Choose Reference Checking Account',
  'choose owner': 'Choose Owner',
  'choose citizen': 'Choose Citizen',
  'account ref': 'Account Ref',
  iban: 'IBAN',
  'enter owner': 'Enter Owner',
  owner: 'Owner',
  'go back': 'Go back',
  ok: 'Ok',
  city: 'City',
  'choose city': 'Choose city',
  'choose state': 'Choose state',
  home: 'Home',
  data: 'Data',
  'legal form': 'Legal form',
  'choose legal form': 'Choose legal form',
  liquidation: 'Liquidation',
  charitable: 'Charitable',
  'website(as evidence)': 'Website(as evidence)',
  'organisation certificate': 'Organisation Certificate',
  'add organisation': 'Add Organisation',
  'drag and drop files here or upload files':
    'Drag and Drop files here or Upload files',
  land: 'Land',
  'organisation class': 'Organisation class',
  'choose organisation class': 'Choose Organisation Class',
  'organisation type': 'Organisation type',
  'choose organisation type': 'Choose Organisation Type',
  'organisation size': 'Organisation size',
  'choose organisation size': 'Choose Organisation Size',
  foundation: 'Foundation',
  'form type': 'Form Type',
  'choose form type': 'Choose Form Type',
  'wish type': 'Wish Type',
  'choose wish type': 'Choose Wish Type',
  'enter description': 'Enter Description',
  'response welcome (no/yes)': 'Response Welcome (No/Yes)',
  documents: 'Documents',
  base: 'Base',
  health: 'Health',
  housing: 'Housing',
  finance: 'Finance',
  education: 'Education',
  work: 'Work',
  mobility: 'Mobility',
  leisure: 'Leisure',
  transaction: 'Transaction',
  'dc indicator': 'Dc Indicator',
  line: 'Line',
  maximum: 'Maximum',
  'members allowed': 'members allowed',
  'a member at least must be provided': 'A member at least must be provided',
  'measurement unit': 'Measurement Unit',
  'position link': 'Position Link',
  quantity: 'Quantity',
  'offset account category': 'Offset Acc. Cat.',
  'offset financial account': 'Offset Account',
  'assign to area': 'Assign to Area',
  send: 'Send',
  attach: 'Attach',
  subject: 'Subject',
  from: 'From',
  to: 'To',
  'from mail': 'Sender',
  'to mail': 'Receiver',
  'mail type': 'Folder',
  date: 'Date',
  size: 'Size',
  'new password': 'New Password',
  'current password': 'Current Password',
  'enter new password': 'Enter new password',
  'select person group': 'Select Person Group',
  'your email': 'Your email',
  "sender's email": "Sender's email",
  "reciever's email": "Reciever's email",
  'your message...': 'Your message...',
  'compose mail': 'Compose mail',
  reply: 'Reply',
  account: 'Account',
  forward: 'Forward',
  'loading...': 'Loading...',
  'add rule': 'Add Rule',
  'data area': 'Data Area',
  'attachment rule': 'Attachment Rule',
  sender: 'Sender',
  text: 'Text',
  'the total size of the selected files exceeds the maximum allowed size of':
    'The total size of the selected files exceeds the maximum allowed size of',

  'amount in space currency': 'Amount in Space Currency',
  'space currency': 'Space Currency',
  'offset category': 'Offset Category',
  post: 'Post',
  'income category': 'Income category',
  'position-ID': 'Positions-ID',
  position: 'Position',
  'account (+)': 'Account (+)',
  'offset-account': 'Offset-Account',
  'expense category': 'Expense category',

  "multiple entries can be delimited with ';' (semicolon)":
    "multiple entries can be delimited with ';' (semicolon)",
  'edit mode': 'Edit Mode',
  'view mode': 'View Mode',
  'view persons': 'View persons',
  'graduation goal': 'Graduation Goal',
  'pre-school': 'Pre-School',
  'general education': 'General Education',
  'vocational school': 'Vocational School',
  'vocschool subject': 'Vocschool subject',
  'vocational training': 'Vocational Training',
  'academic studies': 'Academic Studies',
  'further training': 'Further Training',
  languages: 'Languages',
  'language literacy': 'Language Literacy',
  'language verbal': 'Language Verbal',
  'school type': 'School type',
  school: 'School',
  'school subject': 'School Subject',
  'subject area (if any)': 'Subject Area (if any)',
  'degree achieval': 'Degree achieval',
  'final grade': 'Final grade',
  'last class': 'Last class',
  profession: 'Profession',
  industry: 'Industry',
  'academic institution': 'Academic Institution',
  'choose academic institution': 'Choose Academic Institution',
  'organisation created successfully': 'Organisation created successfully',
  'add organisation': 'Add organisation',
  'create new': 'Create new',
  'add favourite': 'Add favourite',
  'go to org function': 'Go to Org Function',
  'favorite org': 'Favorite Org',
  'no results found': 'No results found',
  'no document selected': 'No document selected',

  'please note duplicate files will be ignored':
    'Please note duplicate files will be ignored',
  'official register no': 'Official register no',
  'general organisation': 'General organisation',
  'choose organization': 'Choose organization',
  'choose organisation category': 'Choose organisation category',
  'organisation category': 'Organisation category',
  'choose school type': 'Choose school type',
  'type of school': 'Type of school',
  yes: 'Yes',
  no: 'No',
  ongoing: 'Ongoing',
  'proficiency level reading/writing': 'Proficiency level reading/writing',
  'proficiency level listening/speaking': 'Proficiency level listening/speaking',
  'subject of studies': 'Subject of studies',
  'study subject': 'Study Subject',
  'this field is required': 'This field is required',
  'aspired school graduation (if any)': 'Aspire School Graduation (if any)',
  'aspired school graduation': 'Aspired School Graduation',
  'subject area': 'Subject area',
  'proficiency level': 'Proficiency level',
  reason: 'Reason',
  'add name': 'Add Name',
  'view name': 'View Name',
  'time dependent name': 'Time Dependent Name',
  update: 'Update',
  layout: 'Layout',
  'organisation created successfully': 'Organisation created successfully',
  'registration institution': 'Registration Institution',
  'click the plus icon to add a last name': 'click the plus icon to add a last name',
  'update name': 'Update Name',
  'must be greater than the Birth Date': 'must be greater than the Birth Date',
  'must be lesser than the Birth Date': 'must be lesser than the Birth Date',
  'last name at birth': 'Last Name at birth',
  'local muncipality': 'Local Muncipality',
  'add contract ': 'Add Contract ',
  'choose data area': 'Choose Data Area',
  continuous: 'Continuous ',
  'contractor is org. or person': 'Contractor is Organisation or Person',
  'contractor ': 'Contractor ',
  'choose contractor': 'Choose Contractor',
  'choose continuous': 'Choose Continuous',
  'choose org. or person': 'Choose Organisation or Person',
  'choose person': 'Choose Person',
  'person role': 'Person Role',
  'person picture': 'Person Picture',
  'person is': 'Person is',
  'choose person role': 'Choose Person Role',
  'org indicator': 'Org Indicator',
  'organisation indicator': 'Org Indicator',
  'org. indicator': 'Org. Indicator',
  buyer: 'Buyer',
  seller: 'Seller',
  'add contract item': 'Add Contract Item',
  customer: 'Customer',
  'customer id': 'Customer ID',
  document: 'Document',
  'contract id': 'Contract ID',
  'start date': 'Start Date',
  'automatic renewal after Run time': 'automatic renewal after Run time',
  'choose auto renewal': 'Choose Auto Renewal',
  'auto renewal': 'Auto Renewal',
  'run time': 'Run Time',
  'run time Unit': 'Run Time Unit',
  'notice period': 'Notice Period',
  'notice period unit': 'Notice Period Unit',
  'the document upload started in the background. you can move on now':
    'The Document upload started in the background. You can move on now',

  'disease history': 'Disease History',
  'select data area': 'Select Data Area',
  'select document type': 'Select Document type',
  'o.t.p browser dependent setting': 'O.T.P Browser Dependent Setting',
  'enter current password': 'Enter current password',
  'change space admin': 'Change Space Admin',

  disease: 'Disease',
  'choose disease': 'Choose Disease',
  'amount (in IC)': 'Amount (in IC)',
  'item curr.': 'Item Curr.',
  'amount (in SC)': 'Amount (in SC)',
  'space curr.': 'Space Curr.',
  inbox: 'Inbox',
  sent: 'Sent',
  trash: 'Trash',
  'attachment rules': 'Attachement Rules',
  forward: 'Forward',
  user: 'User',
  'user setting': 'User Setting',
  'choose date format': 'Choose Date Format',
  'change workspace': 'Change Workspace',
  'inactive / active': 'Inactive / Active',
  document: 'Document',
  currency: 'Currency',
  'select currency': 'Select currency',
  'master data': 'Master Data',
  'city location': 'City Location',
  'choose city location': 'Choose City Location',
  public: 'Public',
  'choose public resp. area': 'Choose Public Resp. Area',
  'org motivation': 'Org Motivation',
  'choose org motivation': 'Choose Org Motivation',
  'legal personality': 'Legal Personality',
  'parent organisation': 'Parent Organisation',
  'choose parent organisation': 'Choose Parent Organisation',
  'non profit': 'Non-profit',
  'choose industry': 'Choose Industry',
  'no. of employees': 'No. of Employees',
  'revenue approx.': 'Revenue approx.',
  'revenue currency': 'Revenue Currency',
  'choose revenue currency': 'Choose Revenue Currency',
  website: 'Website',
  'organization function assignment': 'Organization Function Assignment',
  'organization function': 'Organization Function',
  'issuing bank': 'Issuing Bank',
  'debit/credit': 'Debit/Credit',
  'credit card licenser': 'Credit Card Licenser',
  'license org': 'License Org',
  'card number': 'Card Number',
  'card type': 'Card Type',
  'valid thru': 'Valid Thru',
  loan: 'Loan',
  mortgage: 'Mortgage',
  equity: 'Equity',
  transactions: 'Transactions',
  expenses: 'Expenses',
  expense: 'Expense',
  income: 'Income',
  'asset account': 'Asset Account',
  consumption: 'Consumption',
  'asset shift': 'Asset shifth',
  'debt & asset': 'Debt & Asset',
  vaccination: 'Vaccination',
  'trade name': 'Trade name',
  'batch number': 'Batch number',
  'trade name (vaccine)': 'Trade name (Vaccine)',
  'batch number (vaccine)': 'Batch number (Vaccine)',
  'batch no': 'Batch No',
  'disease protection': 'Disease protection',
  disease: 'Disease',
  vaccine: 'Vaccine',
  fix: 'Fix',
  variable: 'Variable',
  'favourite organization': 'Favourite Organization',
  'organisation (doctor)': 'Organisation (Doctor)',
  'disease list': 'Disease List',
  'vaccination date': 'Vaccination Date',
  close: 'Close',
  'country tel. code': 'Country Tel. Code',
  'tel country code': 'Tel Country Code',
  'choose country tel. code': 'Choose Country Tel. Code',
  'tel. number': 'Tel. number',
  'tel number': 'Tel number',
  'area tel. code': 'Area Tel. Code',
  'tel area code': 'Tel Area Code',
  'choose number format': 'Choose Number Format',
  'companion animal': 'Companion Animal',
  animal: 'Animal',
  'date of death': 'Date of Death',
  'date of birth': 'Date of Birth',
  'selected fields': 'Selected Fields',
  'no of employees': 'No of Employees',
  'publ resp area': 'Public Resp Area',
  'register no': 'Register No',
  'register institute': 'Register Institute',
  'register doc type': 'Reg. Document type',
  'register doc': 'Register Document',
  'parent org': 'Parent Company',
  'main space': 'Main Space',
  'revenue approx': 'Revenue approx.',

  'income for dependent employment': 'Income for dependent employment',
  'income for self-employment': 'Income for self-employment',
  'income from farming': 'Income from farming',
  'income from rent and lease': 'Income from rent and lease',
  'capital income': 'Capital income',
  'business income': 'Business income',
  'other income': 'Other income',

  reversal: 'Reversal',
  reversed: 'Reversed',
  'original doc no': 'Original Doc',
  'reverse doc no': 'Reversal Doc',
  'housing & garden': 'Housing & Garden',
  'heating & ac': 'Heating & AC',
  utilities: 'Utilities',
  electronics: 'Electronics',
  'interior decoration': 'Interior decoration',
  'clothing & shoes': 'Clothing & Shoes',
  health: 'Health',
  'beauty & hygiene': 'Beauty & Hygiene',
  groceries: 'Groceries',
  insurance: 'Insurance',
  'transport (workaday)': 'Transport (workaday)',
  hobby: 'Hobby',
  travel: 'Travel',
  communication: 'Communication',
  'gathering & dining': 'Gathering & Dining',
  donation: 'Donation',
  'education & reading': 'Education & Reading',
  entertainment: 'Entertainment',
  'capital expenditure': 'Capital expenditure',
  others: 'Others',
  dashboard: 'Dashboard',
  expenditure: 'Expenditure',
  disclaimer: 'Disclaimer',
  credits: 'Credits',
  germany: 'Germany',
  'no support': 'no support',
  'authorized managing director': 'Authorized managing director',
  'register court': 'Register court',
  'register number': 'Register number',
  'vat id': 'VAT ID',
  'head office': 'Head office',
  'please mail to': 'Please mail to',
  member: 'Member',
  'choose member': 'Choose member',
  add: 'Add',
  'renewal period': 'Renewal period',
  'renewal period unit': 'Renewal period unit',
  'add document': 'Add document',
  'choose document': 'Choose document',
  'contract continuous?': 'Contract continuous?',
  'choose organisation or person': 'Choose organisation or person',
  'propose a new entry': 'Propose a new entry',
  'new entry': 'New Entry',
  'new value': 'New value',
  'warning!': 'Warning!',
  'please ensure all required fields are populated.':
    'Please ensure all required fields are populated.',
  'success!': 'Success!',
  OK: 'OK',

  'fix or var': 'Fix or Variable',
  'fix or variable': 'Fix or Variable',
  'fixed period unit': 'Fixed period unit',
  'fix period unit': 'Fix period unit',
  'fixed amount': 'Fixed amount',
  'fix amount': 'Fix amount',
  'var service unit': 'Variable service unit',
  'var service rate': 'Variable service rate',
  'variable service unit': 'Variable service unit',
  'variable service rate': 'Variable service rate',
  'counter reading start': 'Counter reading start',
  'counter reading end': 'Counter reading end',
  'workspace successfully changed': 'Workspace successfully changed',
  'please assign a person group to this space':
    'Please assign a person group to this space',
  'failed to change workspace': 'Failed to change workspace',
  'space updated successfully': 'Space updated successfully',

  'counter reading b': 'Counter reading start',
  'counter reading e': 'Counter reading end',
  'issuer is org or person': 'Issuer is Organisation or Person',
  compose: 'Compose',
  'move back to inbox': 'Move back to inbox',
  receiver: 'Receiver',
  'tagged emails': 'Tagged Emails',
  'mark as unread': 'Mark as unread',
  'mark as read': 'Mark as read',
  'other actions': 'Other Actions',
  'export email': 'Export Email',
  'mail has been marked': 'Mail has been marked',
  'mail has been unmarked': 'Mail has been unmarked',

  "you haven't selected any mail to unmark as read.":
    "You haven't selected any mail to unmark as read.",
  "you haven't selected any mail to mark as read.":
    "You haven't selected any mail to mark as read.",
  "you haven't selected any mail to move.": "You haven't selected any mail to move.",
  "you haven't selected any mail to delete.":
    "You haven't selected any mail to delete.",
  "you haven't selected any mail to assign to an area.":
    "You haven't selected any mail to assign to an area.",
  'mail has been assigned': 'Mail has been assigned',

  'work experience added successfully': 'Work Experience added successfully',
  'work experience updated successfully': 'Work Experience updated successfully',

  'go to contract items': 'Go to contract items',
  'contract items': 'Contract Items',
  'this contract has no contract items and is therefore not complete yet. please add at least one item':
    'This contract has no contract items and is therefore not complete yet. Please add at least one item',
  'person added successfully': 'Person added successfully',
  'person updated successfully': 'Person updated successfully',
  'person deleted successfully': 'Person deleted successfully',
  'person group': 'Person Group',
  'person group added successfully': 'Person Group added successfully',
  'person group updated successfully': 'Person Group updated successfully',
  'person group deleted successfully': 'Person Group deleted successfully',
  'person group member added successfully': 'Person Group Member added successfully',
  'person group member updated successfully':
    'Person Group Member updated successfully',
  'person group member deleted successfully':
    'Person Group Member deleted successfully',
  'education added successfully': 'Education added successfully',
  'education updated successfully': 'Education updated successfully',
  'education deleted successfully': 'Education deleted successfully',
  'pre-school added successfully': 'Pre-School added successfully',
  'pre-school updated successfully': 'Pre-School updated successfully',
  'pre-school deleted successfully': 'Pre-School deleted successfully',
  'general education added successfully': 'General Education added successfully',
  'general education updated successfully': 'General Education updated successfully',
  'general education deleted successfully': 'General Education deleted successfully',
  'vocational school added successfully': 'Vocational School added successfully',
  'vocational school updated successfully': 'Vocational School updated successfully',
  'vocational school deleted successfully':
    'Vocational School  deleted successfully',
  'vocational training added successfully': 'Vocational Training added successfully',
  'vocational training updated successfully':
    'Vocational Training updated successfully',
  'vocational training deleted successfully':
    'Vocational Training deleted successfully',
  'academic studies added successfully': 'Acadmic Studies added successfully',
  'academic studies updated successfully': 'Academic Studies updated successfully',
  'academic studies deleted successfully': 'Academic Studies deleted successfully',
  'further training added successfully': 'Further Training added successfully',
  'further training updated successfully': 'Further Training updated successfully',
  'further training deleted successfully': 'Further Training deleted successfully',
  'languages added successfully': 'Languages added successfully',
  'languages updated successfully': 'Languages updated successfully',
  'languages deleted successfully': 'Languages deleted successfully',
  'data added successfully': 'Data added successfully',
  'data updated successfully': 'Data updated successfully',
  'data deleted successfully': 'Data deleted successfully',
  'organisation space successfully created':
    'Organisation space successfully created',
  'personal space successfully created': 'Personal space successfully created',
  'the access request was sent to the space administrator':
    'The access request was sent to the space administrator',
  'default currency saved': 'Default currency saved',
  'member successfully approved': 'Member successfully approved',
  'member could not be approved': 'Member could not be approved',
  'member successfully disapproved': 'Member successfully disapproved',
  'member could not be disapproved': 'Member could not be disapproved',
  'space administrator changed': 'Space administrator changed',
  'organisation updated successfully': 'Organisation updated successfully',
  'your account is successfully removed': 'Your account is successfully removed',
  // 'registration successful. Activation link has been sent to your email': 'Registration successful. Activation link has been sent to your email',
  'check your email for password reset link':
    'Check your email for password reset link',
  'user verified!': 'User verified!',
  'password changed successfully': 'Password changed successfully',
  'password updated successfully': 'password updated successfully',
  'server error please try again': 'Server error please try again',
  'disease added successfully': 'Disease added successfully',
  'disease updated successfully': 'Disease updated successfully',

  'vaccine history disease added successfully':
    'Vaccine History Disease added successfully',
  'vaccine History Disease updated successfully':
    'Vaccine History Disease updated successfully',
  'name added successfully': 'Name added successfully',
  'name updated successfully': 'Name updated successfully',
  'name deleted successfully': 'Name deleted successfully',
  'name could not be deleted': 'Name could not be deleted',
  'mail sent successfully': 'Mail sent successfully',
  'recent mails could not be retrieved': 'Recent mails could not be retrieved',
  'data saved successfully': 'Data saved successfully',
  'data could not be deleted': 'Data could not be deleted',
  'form uploaded successfully': 'Form uploaded successfully',
  'data fetched successfully': 'Data fetched successfully',
  'form updated successfully': 'Form updated successfully',
  'fields updated successfully': 'Fields updated successfully',
  'field Option configuration saved': 'Field Option configuration saved',
  'document saved successfully': 'Document saved successfully',
  'document updated successfully': 'Document updated successfully',
  'document deleted successfully': 'Document deleted successfully',
  'document could not be deleted': 'Document could not be deleted',
  'disease History added successfully': 'Disease History added successfully',
  'disease History updated successfully': 'Disease History updated successfully',
  'Criteria Set created successfully': 'Criteria Set created successfully',
  'criteria Set updated successfully': 'Criteria Set updated successfully',
  'criteria Set deleted successfully': 'Criteria Set deleted successfully',
  'criteria Set could not be deleted': 'Criteria Set could not be deleted',
  'contract items updated': 'Contract items updated',
  'contract created successfully': 'Contract created successfully',
  'companion animal added successfully': 'Companion Animal added successfully',
  'companion animal updated successfully': 'Companion Animal updated successfully',
  'rule added successfully': 'Rule added successfully',
  'rule updated successfully': 'Rule updated successfully',
  'attachment rule deleted successfully': 'Attachment Rule deleted successfully',
  'attachment rule could not be deleted': 'Attachment Rule could not be deleted',
  'academic studies added successfully': 'Academic studies added successfully',
  'academic studies updated successfully': 'Academic studies updated successfully',
  'bank account created successfully': 'Bank Account created successfully',
  'bank account deleted successfully': 'Bank Account deleted successfully',
  'bank account updated successfully': 'Bank Account updated successfully',
  'problem fetching credit card': 'Problem fetching credit card',
  'credit card created successfully': 'Credit card created successfully',
  'problem creating credit card': 'Problem creating credit card',
  'credit card updated successfully': 'Credit card updated successfully',
  'problem updating credit card': 'Problem updating credit card',
  'problem fetching transaction': 'Problem fetching transaction',
  'transaction created successfully': 'Transaction created successfully',
  'problem creating Transaction': 'Problem creating Transaction',
  'transaction updated successfully': 'Transaction updated successfully',
  'problem updating transaction': 'Problem updating transaction',
  'I Agree With the Terms and Conditions': ' I Agree With the Terms and Conditions',

  'address added successfully': 'Address added successfully',
  'address updated successfully': 'Address updated successfully',

  'residence added successfully': 'Residence added successfully',
  'residence updated successfully': 'Residence updated successfully',

  'contact added successfully': 'Contact added successfully',
  'contact updated successfully': 'Contact updated successfully',

  'citizenship added successfully': 'Citizenship added successfully',
  'citizenship updated successfully': 'Citizenship updated successfully',

  Username: 'Username',
  'Personal User': 'Personal User',
  'Have an account': 'Have an account',
  Register: 'Register',
  Login: 'Login',
  Password: 'Password',
  'Forgot Password': 'Forgot Password',
  Copyright: 'Copyright',
  'All rights reserved': 'All rights reserved',
  'sign in': 'Sign in',
  'join personal workspace': 'Join Personal Workspace',
  'create personal workspace': 'Create Personal Workspace',
  'Organisation User': 'Organisation User',
  'total inbox': 'No. of Emails',
  'total liability': 'Liabilities',
  liability: 'Liability',
  assets: 'Assets',
  'total asset': 'Assets',
  'your contract will end': 'The Contract will end',
  'one box': 'One Box',
  folder: 'Folder',
  'full text search': 'Full text search',
  'delete document': 'Delete Document',
  'are you sure you want to delete this Document?':
    'Are you sure you want to delete this Document?',
  delete: 'DELETE',
  'document Category': 'Document Category',
  'filter criteria': 'Filter Criteria',
  'no document uploaded yet!': 'No document uploaded yet!',
  'no result': 'No result',
  'no result was found...': 'No result was found...',
  space: 'Space',
  'file name': 'File name',
  'issuing organization': 'Issuing organization',
  "organisation's country": "Organisation's country",
  "organisation's state": "Organisation's state",
  "organisation's city": "Organisation's city",
  'document category': 'Document category',
  'last updated': 'Last updated',
  'document size': 'Document size',
  'no document seleted': 'No document seleted',
  'file details': 'File details',
  'results found': 'Results found',
  'result(s)': 'result(s)',
  'storage exceeded 1 gb. please clean up and remove files.':
    'Storage exceeded 1 GB. Please clean up and remove files.',
  'storage exceeded 100 mb. please clean up and remove files.':
    'Storage exceeded 100 MB. Please clean up and remove files.',
  'the file exceed the maximum allowed file size of':
    'The file exceed the maximum allowed file size of',
  'the total size of the selected files exceeds the maximum allowed size of':
    'The total size of the selected files exceeds the maximum allowed size of',
  loading: 'Loading',
  faqs: 'FAQs',
  'space group': 'Space Group',
  liabilities: 'Liabilities',
  'assets vs. liabilities': 'Assets vs. Liabilities',
  'assets vs liabilities': 'Assets vs Liabilities',
  'asset vs liability': 'Asset vs Liability',
  'liability.': 'Liability.',
  'income vs. expenses': 'Income vs. Expenses',
  loss: 'Loss',
  profit: 'profit',
  notifications: 'Notifications',
  'expenses pie chart': 'Expenses Pie Chart',
  emails: 'Emails',
  documents: 'Documents',
  'storage space': 'Storage Space',
  'top 5 contracts': 'Top 5 Contracts',
  'income & expenses line chart': 'Income & Expenses Line Chart',
  print: 'Print',
  unread: 'unread',
  read: 'read',
  of: 'OF',
  'in use': 'IN USE',
  'no contracts yet!': 'No contracts yet!',

  'asset vs liability': 'Asset vs Liability',
  'liability.': 'Liability.',

  phone: 'Phone',
  fax: 'Fax',
  'general contact': 'General Contact',
  'represented by': 'Represented by',
  credits: 'Credits',
  contact: 'Contact',
  'files have been appended.': 'Files have been appended.',
  'attachments size must be less than 8 mb.':
    'Attachments size must be less than 8 MB.',
  'attach documents': 'Attach documents',
  attachments: 'Attachments',

  'membership added successfully': 'Membership added successfully',
  'membership updated successfully': 'Membership updated successfully',

  'your contract': 'Your contract',
  // 'will end': 'will end',
  'this contract': 'This contract',
  'will end': 'will end',
  'the document will expire': 'The document will expire',
  'will end on': 'will end on',
  'the run time of your contract': 'The run time of your contract',
  "if you don't wish to renew it you can cancel it":
    "if you don't wish to renew it you can cancel it",
  'this document will end': 'This document will end',
  'if neccesary, please take care for a new one':
    'if neccesary, please take care for a new one',
  "if you don't wish to renew it you can cancel it before":
    "if you don't wish to renew it you can cancel it before",
  'the document': 'The Document',
  document: 'Document',
  'will expire on': 'will expire on',
  'will expire on the': 'will expire on the',
  of: 'of',
  'payment status': 'Payment Status',
  'paypal reference': 'Paypal Reference',
  'start date': 'Start Date',
  'end date': 'End Date',
  'local court ': 'Local court ',

  initiated: 'Initiated',
  success: 'Success',
  'p.o. box': 'P.O. Box',

  'no document available': 'No Document Available',
  'no notifications yet!': 'No notifications yet!',
  'no entries yet!': 'No entries yet!',
  'you have not created any space groups!': 'You have not created any space groups!',

  'we will review your proposal. if successful, it will be added in the next 5 days. thank you!':
    'We will review your proposal. If successful, it will be added in the next 5 days. Thank you!',

  'liability for content': 'Liability for Content',
  'the content provided has been created with greatest care. however we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. as a service provider according to § 7 (1) of the german telemedia act (tmg), we are responsible for own content on these sites under the general laws. however, corresponding to §§ 8 to 10 tmg, it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law.':
    'The content provided has been created with greatest care. However we cannot guarantee for the accuracy, completeness, reliability, usability and timeliness of the content. As a service provider according to § 7 (1) of the German Telemedia Act (TMG), we are responsible for own content on these sites under the general laws. However, corresponding to §§ 8 to 10 TMG, it is not our duty, to control or check information transmitted or saved from third parties, whether or not they are in line with the law.',
  'obligations to remove or block the use of information under the general laws remain unaffected. however, liability is only possible from the date of knowledge of a specific infringement. upon notification of such violations, we will remove the content immediately.':
    'Obligations to remove or block the use of information under the general laws remain unaffected. However, liability is only possible from the date of knowledge of a specific infringement. Upon notification of such violations, we will remove the content immediately.',

  copyrights: 'Copyrights',

  'the contents and works on these pages compiled by us are subject to copyright law. copying, processing, distribution and any kind of use outside the limits of copyright law require the written consent of us.':
    'The contents and works on these pages compiled by us are subject to copyright law. Copying, processing, distribution and any kind of use outside the limits of copyright law require the written consent of us.',

  'in case the content is not created by us, the copyrights of third parties are being observed. in particular contents of third parties are marked as such. however, if a user becomes aware of a copyright infringement, we ask the user for notification. upon notification of such violations, we will remove the content immediately.':
    'In case the content is not created by us, the copyrights of third parties are being observed. In particular contents of third parties are marked as such. However, if a user becomes aware of a copyright infringement, we ask the user for notification. Upon notification of such violations, we will remove the content immediately.',

  'data protection': 'Data Protection',

  'the collection of person-related data on our website is based, as far as possible, on voluntariness. these data will not be transmitted to third parties without your explicit approval.':
    'The collection of person-related data on our website is based, as far as possible, on voluntariness. These data will not be transmitted to third parties without your explicit approval.',

  'please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. therefore a complete protection of data from access of third parties is not possible.':
    'Please be aware, that the data transmission over the internet (e.g. communication via email) may bear security risks. Therefore a complete protection of data from access of third parties is not possible.',

  'we hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. the site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.':
    'We hereby expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information material. The site operators reserve the right to take legal action in the event that unsolicited advertising information is sent, such as spam e-mails.',

  'what is admincat? and why should i use admincat?':
    'What is AdminCat? And why should I use AdminCat?',
  'admincat is a unique internet service solution for private households striving to completely digitalize and interconnect your paperwork and personal data. this way you can save administrative time and get a more powerful control over your entire life.':
    'AdminCat is a unique internet service solution for private households striving to completely digitalize and interconnect your paperwork and personal data. This way you can save administrative time and get a more powerful control over your entire life.',

  'which key features does admincat offer?':
    'Which key features does AdminCat offer?',
  'with the integrated email-service, attached documents can get saved automatically where they belong and with the right categorization. an ocr text extraction allows a quick and easy full-text search. admincat also allows managing contracts and financial data in over 50 currencies with daily exchange rate updates. reminders make you aware of upcoming expiration of contracts and important documents. and as a cloud service you can reach admincat from anywhere and at anytime. but the best is still to come as admincat is seeking to be your all-in-one admin solution.':
    'With the integrated Email-Service, attached documents can get saved automatically where they belong and with the right categorization. An OCR text extraction allows a quick and easy full-text search. AdminCat also allows managing contracts and financial data in over 50 currencies with daily exchange rate updates. Reminders make you aware of upcoming expiration of contracts and important documents. And as a cloud service you can reach AdminCat from anywhere and at anytime. But the best is still to come as AdminCat is seeking to be your all-in-one admin solution.',

  'which limitations at admincat do i need to know?':
    'Which limitations at AdminCat do I need to know?',
  'admincat currently offers a data storage of up to 1 gb per workspace. uploads are possible for documents not greater than 10 mb. and excessively sending emails (>300 a day) are not allowed. for this please also see our terms and conditions.':
    'AdminCat currently offers a data storage of up to 1 GB per workspace. Uploads are possible for documents not greater than 10 MB. And excessively sending emails (>300 a day) are not allowed. For this please also see our Terms and Conditions.',

  'how much does it cost to use admincat?': 'How much does it cost to use AdminCat?',
  'we offer a 30-days free of charge trial period to every user initially. after that all of the admincat services and features are offered for a monthly rate of 2 € or a yearly rate of only 19 €.':
    'We offer a 30-days free of charge trial period to every user initially. After that all of the AdminCat services and features are offered for a monthly rate of 2 € or a yearly rate of only 19 €.',

  'is there a notice period for cancelling the contract?':
    'Is there a notice period for cancelling the contract?',
  'no, the contract is valid until the end of the prepayment period. this means that your payment alone defines the end of the contract. all user accounts are kept anonymous and hence it is technically not foreseen to offer a premature contract cancellation and payback option.':
    'No, the contract is valid until the end of the prepayment period. This means that your payment alone defines the end of the contract. All user accounts are kept anonymous and hence it is technically not foreseen to offer a premature contract cancellation and payback option.',

  'does admincat automatically delete my data after the contract has ended?':
    'Does AdminCat automatically delete my data after the contract has ended?',
  'no. all your workspaces that still have at least one valid and subscribed user will continue to exist. if you haven’t assigned a new admin for them, the system will choose a subscribed successor automatically. workspaces, which are assigned to inactive users only, will also remain in the system - for another two months. during that time, the first user to renew his or her subscription will become the new admin of such a space.':
    'No. All your workspaces that still have at least one valid and subscribed user will continue to exist. If you haven’t assigned a new admin for them, the system will choose a subscribed successor automatically. Workspaces, which are assigned to inactive users only, will also remain in the system - for another two months. During that time, the first user to renew his or her subscription will become the new admin of such a space.',

  'what happens with spaces that have no admin for more than two months?':
    'What happens with spaces that have no admin for more than two months?',
  'spaces abandoned for more than two months will get deleted automatically. an automatic and immediate deletion also takes place, if all assigned users have already deleted their accounts.':
    'Spaces abandoned for more than two months will get deleted automatically. An automatic and immediate deletion also takes place, if all assigned users have already deleted their accounts.',

  'are users also getting deleted automatically?':
    'Are users also getting deleted automatically?',
  'inactive (unsubscribed) users, that have no active space assignment, will get deleted automatically after one month. this concerns users for instance, which either never completed the registration or space acceptance process or whose spaces got deleted because of the two months inactivity described above.':
    'Inactive (unsubscribed) users, that have no active space assignment, will get deleted automatically after one month. This concerns users for instance, which either never completed the registration or space acceptance process or whose spaces got deleted because of the two months inactivity described above.',

  'is my data safe on admincat?': 'Is my data safe on AdminCat?',
  'for security reasons we constantly keep our software packages up-to-date to avoid exploits. additionally to the standard encryption of our server provider, we encrypt your data a second time, such that even our staff can’t read it. high password standards, a two-factor-authentication (totp), and the automatic session expiration after inactivity, protects all user accounts. we use the secure https protocol for data transmissions through the internet of course and daily backups are stored for the unlikely event of a major data loss.':
    'For security reasons we constantly keep our software packages up-to-date to avoid exploits. Additionally to the standard encryption of our server provider, we encrypt your data a second time, such that even our staff can’t read it. High password standards, a Two-Factor-Authentication (TOTP), and the automatic session expiration after inactivity, protects all user accounts. We use the secure HTTPS protocol for data transmissions through the internet of course and daily backups are stored for the unlikely event of a major data loss.',

  'do you recommend additional security measures for users?':
    'Do you recommend additional security measures for users?',
  'we recommend users to obey general security rules concerning password protection and special prudence against malicious phishing mails. additionally you can export your emails from admincat to your local machine manually or establish a synchronization with your email program as an own backup.':
    'We recommend users to obey general security rules concerning password protection and special prudence against malicious phishing mails. Additionally you can export your emails from AdminCat to your local machine manually or establish a synchronization with your Email program as an own backup.',

  'are you subject to the gdpr standard in the eu?':
    'Are you subject to the GDPR standard in the EU?',
  'yes, admincat exclusively runs on servers located in frankfurt (germany) and is therefore subject to one of the highest privacy-protecting regulations in the world – the gdpr of the european union. for this reason we have contracted an external data security officer, which is regularly checking, monitoring and improving our security processes.':
    'Yes, AdminCat exclusively runs on servers located in Frankfurt (Germany) and is therefore subject to one of the highest privacy-protecting regulations in the world – the GDPR of the European Union. For this reason we have contracted an external data security officer, which is regularly checking, monitoring and improving our security processes.',

  'does admincat also offer access via app?':
    'Does AdminCat also offer access via App?',
  'not yet. at first we want you to enjoy the generous user experience that our website offers for large-screen devices. but we are planning to introduce a mobile app in the future as well.':
    'Not yet. At first we want you to enjoy the generous user experience that our website offers for large-screen devices. But we are planning to introduce a mobile app in the future as well.',

  'how should i get started with my new account and space?':
    'How should I get started with my new account and Space?',
  'add persons, person groups and favorite organizations. once defined, these units can get assigned to your space, your documents and data like contracts and financial transactions. once logged in you can also check out our quick-tour videos for more recommendations. or you visit our channel series on youtube.':
    'Add persons, person groups and favorite organizations. Once defined, these units can get assigned to your Space, your documents and data like contracts and financial transactions. Once logged in you can also check out our Quick-Tour videos for more recommendations. Or you visit our channel series on YouTube.',

  'does every person as member of a space need a user account?':
    'Does every person as member of a Space need a user account?',
  'no – like in a household, where only one or two people actively do the paperwork for all. in fact, a user may even be a third person taking care for the household’s administration.':
    'No – like in a household, where only one or two people actively do the paperwork for all. In fact, a user may even be a third person taking care for the household’s administration.',

  'what is the basis for the data on the dashboard?':
    'What is the basis for the data on the Dashboard?',
  'each space represents a person group, which may be the members of a household or another type of personal economic unit. the dashboard displays data corresponding to this person group. if the person group members change, the financial data on the dashboard changes too.':
    'Each Space represents a person group, which may be the members of a household or another type of personal economic unit. The dashboard displays data corresponding to this person group. If the person group members change, the financial data on the dashboard changes too.',

  'how can i contact admincat in case of questions or feedback?':
    'How can I contact AdminCat in case of questions or feedback?',
  'as an active user you can find the option "contact us" in the user menu on the top right. else you can also write to support@admincat.net directly. we appreciate your valuable comments and feedback, which will of course play an important role in our further product development. but please be aware, that we can only give general support and guidance, as your data is invisible for us on the database.':
    'As an active user you can find the option “Contact us” in the user menu on the top right. Else you can also write to support@admincat.net directly. We appreciate your valuable comments and feedback, which will of course play an important role in our further product development. But please be aware, that we can only give general support and guidance, as your data is invisible for us on the database.',

  'could i join the admincat team?': 'Could I join the AdminCat team?',
  'of course! we are honored by your interest. just send us an email and describe your main experiences and biggest strengths and we will come back to you.':
    'Of course! We are honored by your interest. Just send us an email and describe your main experiences and biggest strengths and we will come back to you.',

  'we are here for you!': 'We are here for you!',
  'check out admincat today! it’s free.': 'Check out AdminCat today! It’s free.',
  'press the button below in order to start with your first own admincat workspace, you’re gonna love it!':
    'Press the button below in order to start with your first own AdminCat workspace, You’re gonna love it!',
  'get started': 'Get started',

  'privacy statement': 'Privacy Statement',
  'thank you for your interest in our online service. the protection of your data is very important to us. we therefore apply the utmost care and state-of-the-art security standards to ensure maximum protection of your data. below we will explain in detail, which data admincat is processing and how it protects this data.':
    'Thank you for your interest in our online service. The protection of your data is very important to us. We therefore apply the utmost care and state-of-the-art security standards to ensure maximum protection of your data. Below we will explain in detail, which data AdminCat is processing and how it protects this data.',

  'the person responsible for data processing is':
    'The person responsible for data processing is',

  'you use admincat free of charge!': 'You use AdminCat free of charge!',

  'legal basis': 'Legal basis',
  "the provision of our services requires the processing of data and takes place in accordance with the statutory provisions. for data protection, the general data protection regulation (gdpr), the applicable federal data protection act (bdsg), the telemedia act (tmg) and telecommunications secrecy are decisive. admincat's services are hosted exclusively on servers in germany (frankfurt am main).":
    "The provision of our services requires the processing of data and takes place in accordance with the statutory provisions. For data protection, the General Data Protection Regulation (GDPR), the applicable Federal Data Protection Act (BDSG), the Telemedia Act (TMG) and telecommunications secrecy are decisive. AdminCat's services are hosted exclusively on servers in Germany (Frankfurt am Main).",

  terms: 'Terms',
  "the legislator requires that personal data be processed in a lawful manner, in good faith and in a manner that is understandable for the person concerned ('lawfulness, processing in good faith, transparency'). to ensure this, we inform you about the individual legal definitions that are also used in this data protection declaration":
    "The legislator requires that personal data be processed in a lawful manner, in good faith and in a manner that is understandable for the person concerned ('lawfulness, processing in good faith, transparency'). To ensure this, we inform you about the individual legal definitions that are also used in this data protection declaration",

  'personal data': 'Personal data',
  '"personal data" is all information that relates to an identified or identifiable natural person (hereinafter “data subject”); a natural person is regarded as identifiable who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person.':
    '“Personal data” is all information that relates to an identified or identifiable natural person (hereinafter “data subject”); A natural person is regarded as identifiable who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person.',

  processing: 'Processing',
  '"processing" is any process carried out, with or without the help of automated processes, or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading out, querying, use, disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.':
    '“Processing” is any process carried out, with or without the help of automated processes, or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading out, querying, use, disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.',

  'restriction of processing': 'Restriction of processing',
  '"restriction of processing" is the marking of stored personal data with the aim of restricting their future processing.':
    '"Restriction of processing" is the marking of stored personal data with the aim of restricting their future processing.',

  profiling: 'Profiling',
  '"profiling" is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health,to analyze or predict personal preferences, interests, reliability, behavior, whereabouts or relocation of this natural person.':
    '"Profiling" is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health,to analyze or predict personal preferences, interests, reliability, behavior, whereabouts or relocation of this natural person.',

  pseudonymization: 'Pseudonymization',
  '"pseudonymization" is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.':
    '"Pseudonymization" is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.',

  'file system': 'File system',
  '"file system" is any structured collection of personal data that is accessible according to certain criteria, regardless of whether this collection is managed centrally, decentralized or according to functional or geographical criteria':
    '“File system” is any structured collection of personal data that is accessible according to certain criteria, regardless of whether this collection is managed centrally, decentralized or according to functional or geographical criteria',

  'person responsible': 'Person responsible',
  '"person responsible" is a natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data; if the purposes and means of this processing are specified by union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with union law or the law of the member states.':
    '"Person responsible" is a natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data; If the purposes and means of this processing are specified by Union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with Union law or the law of the member states.',

  'access data': 'Access Data',
  'admincat collects data and information, some of which is personal data, each time the website is accessed. this data is stored in the server log files. the following can be recorded':
    'AdminCat collects data and information, some of which is personal data, each time the website is accessed. This data is stored in the server log files. The following can be recorded',
  'web request and interaction with a service, e.g. paypal':
    'Web request and interaction with a service, e.g. PayPal',
  'the internet protocol address (ip address)':
    'The Internet Protocol address (IP address)',
  'browser type and language of the browser':
    'Browser type and language of the browser',
  'date and time of access to our website': 'Date and time of access to our website',
  'time zone difference to greenwich mean time (gmt)':
    'Time zone difference to Greenwich Mean Time (GMT)',
  'the email address of a space, if a user is logged in there':
    'The email address of a Space, if a user is logged in there',
  'access status/https status code': 'Access status/HTTPS status code',

  'the legal basis for data processing is art. 6 (1) p. 1 lit. f gdpr. our legitimate interest is based on the following purposes for data collection':
    'The legal basis for data processing is Art. 6 (1) p. 1 lit. f GDPR. Our legitimate interest is based on the following purposes for data collection',
  'ensuring a smooth connection to the website':
    'Ensuring a smooth connection to the website',
  'ensuring a convenient use of our website':
    'Ensuring a convenient use of our website',
  'evaluation of system security and stability, and':
    'Evaluation of system security and stability, and',
  'for other administrative purposes': 'For other administrative purposes',
  'after the end of the communication process, the collected information is only evaluated for statistical purposes and in anonymized form. under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.':
    'After the end of the communication process, the collected information is only evaluated for statistical purposes and in anonymized form. Under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.',

  cookies: 'Cookies',
  "admincat doesn't use any cookie. therefore we also don't provide an integrated cookie consent tool on our site, which usually allows you to administrate your opt-in choices related to certain cookies and their features.":
    "AdminCat doesn't use any cookie. Therefore we also don't provide an integrated cookie consent tool on our site, which usually allows you to administrate your opt-in choices related to certain cookies and their features.",

  'inventory and payment data': 'Inventory and payment data',
  'all you need to create a user account is an e-mail address. we use this to send you a link to confirm your registration and to reset your password. we do not collect any other inventory data. your email address is not passed on to third parties, nor do we use third-party data to enrich your email address with this data and create a profile about you.':
    'All you need to create a user account is an e-mail address. We use this to send you a link to confirm your registration and to reset your password. We do not collect any other inventory data. Your email address is not passed on to third parties, nor do we use third-party data to enrich your email address with this data and create a profile about you.',

  'although you can voluntarily enter or view extensive data in a space to which you have been granted access (about people, addresses, health data, contract data and much more), the members of these spaces are themselves responsible for the data managed there. admincat employees have virtually no access to this data (see explanations under 7. content data).':
    'Although you can voluntarily enter or view extensive data in a Space to which you have been granted access (about people, addresses, health data, contract data and much more), the members of these Spaces are themselves responsible for the data managed there. AdminCat employees have virtually no access to this data (see explanations under 7. Content data).',

  'you can pay for our services, such as the paid subscription, via payment services such as paypal. the amount, the subscription period and a paypal reference in connection with your user account are stored in our database for you as confirmation and overview for each payment transaction processed via this service, but only in double-encrypted form, so that our employees have no access to this data.':
    'You can pay for our services, such as the paid subscription, via payment services such as PayPal. The amount, the subscription period and a PayPal reference in connection with your user account are stored in our database for you as confirmation and overview for each payment transaction processed via this service, but only in double-encrypted form, so that our employees have no access to this data.',

  'content data (email, documents and recorded data)':
    'Content data (email, documents and recorded data)',
  'admincat manages emails, documents and extensive master and transaction data for you (insofar as you decide to use it). only you as the user should have sovereignty and full control over this data. that is why we offer you maximum protection with our security concept':
    'AdminCat manages emails, documents and extensive master and transaction data for you (insofar as you decide to use it). Only you as the user should have sovereignty and full control over this data. That is why we offer you maximum protection with our security concept',

  'admincat is hosted on an aws server in frankfurt am main (germany) and is therefore subject to the strictest eu data protection regulations; aws has iso/iec certifications 27001:2022, 27017:2015 and 27018:2019':
    'AdminCat is hosted on an AWS server in Frankfurt am Main (Germany) and is therefore subject to the strictest EU data protection regulations; AWS has ISO/IEC certifications 27001:2022, 27017:2015 and 27018:2019',
  'we secure communication with your end device using the modern tls 1.3 (transport layer security) standard, which means that all data is transmitted in encrypted form and protected against manipulation and unauthorized access by third parties during transmission':
    'We secure communication with your end device using the modern TLS 1.3 (Transport Layer Security) standard, which means that all data is transmitted in encrypted form and protected against manipulation and unauthorized access by third parties during transmission',
  'we counter potential attackers with high password standards, automatic session termination after 1 hour of inactivity and optional two-factor authentication (2fa)':
    'We counter potential attackers with high password standards, automatic session termination after 1 hour of inactivity and optional two-factor authentication (2FA)',
  'in addition to aws encryption, your content data is stored on a second encrypted database and access to it is only permitted using keys assigned to your login data':
    'In addition to AWS encryption, your content data is stored on a second encrypted database and access to it is only permitted using keys assigned to your login data',
  'the column names in the tables of our database are still masked in such a way that an unauthorized reader can hardly interpret or assign the content of the columns':
    'The column names in the tables of our database are still masked in such a way that an unauthorized reader can hardly interpret or assign the content of the columns',
  'in rare cases, complete encryption of the data is associated with unacceptable performance losses, e.g. for full-text searches in documents uploaded by you. therefore, instead of secondary encryption, rigorous text splitting is used here, which means that the specific text content remains protected for you':
    'In rare cases, complete encryption of the data is associated with unacceptable performance losses, e.g. for full-text searches in documents uploaded by you. Therefore, instead of secondary encryption, rigorous text splitting is used here, which means that the specific text content remains protected for you',

  'service contact (contact form, email, phone)':
    'Service contact (contact form, email, phone)',
  'you can contact us via a contact form (accessible from your user account), by email or by phone. these communication channels are generally open to you for your questions. we are also very interested in your feedback, what you like and your suggestions for improvements.':
    'You can contact us via a contact form (accessible from your user account), by email or by phone. These communication channels are generally open to you for your questions. We are also very interested in your feedback, what you like and your suggestions for improvements.',

  'personal data is not required, unless you may wish to be called back or receive an answer by email. to analyze errors on the site, we ask you for a description of the error as detailed as possible, but we will never ask for personal data or even login data. seven days after each request has been completed, we delete all related messages in our systems. your telephone number will remain on a call list on one of our telephones for one month without a name reference until it is deleted; no additional storage or further processing will take place.':
    'Personal data is not required, unless you may wish to be called back or receive an answer by email. To analyze errors on the site, we ask you for a description of the error as detailed as possible, but we will never ask for personal data or even login data. Seven days after each request has been completed, we delete all related messages in our systems. Your telephone number will remain on a call list on one of our telephones for one month without a name reference until it is deleted; no additional storage or further processing will take place.',

  others: 'Others',
  'admincat does not pass on any data to third parties (except in the case of a legal obligation). the site also does not use any social media plug-ins (such as for facebook, instagram or twitter). we also do not use tracking and analysis services such as google analytics. furthermore, we completely dispense with so-called user profiling and automated decisions based on it, as well as the display of advertising and the sending of newsletters.':
    'AdminCat does not pass on any data to third parties (except in the case of a legal obligation). The site also does not use any social media Plug-Ins (such as for Facebook, Instagram or Twitter). We also do not use tracking and analysis services such as Google Analytics. Furthermore, we completely dispense with so-called user profiling and automated decisions based on it, as well as the display of advertising and the sending of newsletters.',

  'disclosure of data': 'Disclosure of data',
  'admincat does not pass on any data to third parties unless there is a legal obligation to do so or the transfer of the data is absolutely necessary for the provision of a service requested by the user (art. 6 para. 1 lit. f gdpr). this section provides information about such services, our partners involved in them and what data is used for what purpose. users who make use of these services on our website tacitly consent to the corresponding processing.':
    'AdminCat does not pass on any data to third parties unless there is a legal obligation to do so or the transfer of the data is absolutely necessary for the provision of a service requested by the user (Art. 6 para. 1 lit. f GDPR). This section provides information about such services, our partners involved in them and what data is used for what purpose. Users who make use of these services on our website tacitly consent to the corresponding processing.',
  'aws is our hosting partner, on whose servers our website is operated in frankfurt am main (germany). this means that aws is of course bound by the strict european data protection laws (gdpr), and under no circumstances may it access your data or pass data on to third parties. the open source software awstats is used on its servers to analyze log files, which means that no cookies are required by your browser. aws only uses anonymized ip address data for this purpose. this data cannot be assigned to a specific person and is not forwarded to third parties.':
    'AWS is our hosting partner, on whose servers our website is operated in Frankfurt am Main (Germany). This means that AWS is of course bound by the strict European data protection laws (GDPR), and under no circumstances may it access your data or pass data on to third parties. The open source software AWStats is used on its servers to analyze log files, which means that no cookies are required by your browser. AWS only uses anonymized IP address data for this purpose. This data cannot be assigned to a specific person and is not forwarded to third parties.',
  'if you take out a subscription on our website, you can use the service provider paypal for payment. to do this, you need a corresponding user account with paypal, for which you agree to the terms and conditions and privacy policy accordingly. for each payment process, admincat only transmits the absolutely necessary data such as provider, amount and currency and receives the status and a paypal reference number of the payment from paypal.':
    'If you take out a subscription on our website, you can use the service provider PayPal for payment. To do this, you need a corresponding user account with PayPal, for which you agree to the terms and conditions and privacy policy accordingly. For each payment process, AdminCat only transmits the absolutely necessary data such as provider, amount and currency and receives the status and a PayPal reference number of the payment from PayPal.',
  'you will find embedded videos on our pages that are provided by the youtube service. youtube is operated by google ireland limited, gordon house, 4 barrow st, dublin, d04 e5w5, ireland. when you play a video, a direct connection to the google servers is established. your ip address and the address of the page from which you are watching the video will be transmitted. youtube also uses a cookie that collects further user data in order to offer matching videos. if you have a google user account and are currently logged in, youtube can link data, such as the video you clicked on, to your google user account. if you do not wish this to happen, you must log out of google':
    'You will find embedded videos on our pages that are provided by the YouTube service. YouTube is operated by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. When you play a video, a direct connection to the Google servers is established. Your IP address and the address of the page from which you are watching the video will be transmitted. YouTube also uses a cookie that collects further user data in order to offer matching videos. If you have a Google user account and are currently logged in, YouTube can link data, such as the video you clicked on, to your Google user account. If you do not wish this to happen, you must log out of Google.',
  "we also use google fonts on our website, that are loaded onto your device from the google fonts servers by your browser. in this way, we can consistently present our texts in an appealing form (art. 6 para. 1 lit. f gdpr). you can find more information about google's data protection at":
    "We also use Google Fonts on our website, that are loaded onto your device from the Google Fonts servers by your browser. In this way, we can consistently present our texts in an appealing form (Art. 6 para. 1 lit. f GDPR). You can find more information about Google's data protection at",
  'https://policies.google.com/privacy.': 'https://policies.google.com/privacy.',
  'admincat does not use social media plugins (e.g. for facebook, instagram or x). we also do not use tracking and analysis services such as google analytics. furthermore, we do not use user profiling and automated decisions based on this, nor do we display advertising or send newsletters.':
    'AdminCat does not use social media plugins (e.g. for Facebook, Instagram or X). We also do not use tracking and analysis services such as Google Analytics. Furthermore, we do not use user profiling and automated decisions based on this, nor do we display advertising or send newsletters.',

  'data protection rights of the data subject':
    'Data protection rights of the data subject',

  'conditions for consent': 'Conditions for consent',
  'if the processing of personal data is based on consent given, you have the right to revoke your consent at any time in accordance with':
    'If the processing of personal data is based on consent given, you have the right to revoke your consent at any time in accordance with',
  'art. 7 paragraph 3 gdpr': 'Art. 7 Paragraph 3 GDPR',
  'revoking your consent does not affect the legality of the processing carried out on the basis of your consent up to the point of revocation.':
    'Revoking your consent does not affect the legality of the processing carried out on the basis of your consent up to the point of revocation.',

  'you can contact us at any time to exercise your right of withdrawal.':
    'You can contact us at any time to exercise your right of withdrawal.',

  'right of access by the data subject': 'Right of access by the data subject',
  'in accordance with': 'In accordance with',
  'art. 15 gdpr': 'Art. 15 GDPR',
  'you have the right to request a confirmation from admincat as to whether we are processing personal data related to you. if that is the case, you have the right to information about this personal data, the details of the processing and comprehensive information about your rights. you can request confirmation at any time using the contact details above.':
    'you have the right to request a confirmation from AdminCat as to whether we are processing personal data related to you. If that is the case, you have the right to information about this personal data, the details of the processing and comprehensive information about your rights. You can request confirmation at any time using the contact details above.',

  'right to rectification': 'Right to rectification',
  'in accordance with': 'In accordance with',
  'art. 16 gdpr': 'Art. 16 GDPR',
  'you have the right to demand immediate rectification or completion of personal data about you that is administered by us.':
    'you have the right to demand immediate rectification or completion of personal data about you that is administered by us.',

  'right to erasure (right to be forgotten)':
    'Right to erasure (right to be forgotten)',
  'in accordance with': 'In accordance with',
  'art. 17 gdpr': 'Art. 17 GDPR',
  'you have the right to request the immediate deletion of personal data that admincat has stored about you, provided that it is no longer necessary for the purposes for which it was collected or otherwise processed.':
    'you have the right to request the immediate deletion of personal data that AdminCat has stored about you, provided that it is no longer necessary for the purposes for which it was collected or otherwise processed.',

  'right to restriction of processing': 'Right to restriction of processing',
  'according to': 'According to',
  'art. 18 gdpr': 'Art. 18 GDPR',
  'any data subject affected by the processing of personal data, has the right to require the person responsible for restricting the processing, if such information is incorrect, the processing illegitimate or inexpedient, or if an objection according to':
    'any data subject affected by the processing of personal data, has the right to require the person responsible for restricting the processing, if such information is incorrect, the processing illegitimate or inexpedient, or if an objection according to',
  'art. 21 paragraph 1': 'Art. 21 paragraph 1',
  'is present.': 'is present.',

  'in order to exercise the right to restriction of processing, the data subject can contact us at any time using the contact details given above.':
    'In order to exercise the right to restriction of processing, the data subject can contact us at any time using the contact details given above.',

  'notification obligation to receiving third parties':
    'Notification obligation to receiving third parties',

  'if the person responsible is sharing personal data of the data subject with third parties, according to':
    'If the person responsible is sharing personal data of the data subject with third parties, according to',

  'art. 19 gdpr': 'Art. 19 GDPR',
  'he must notify these recipients when personal data have been corrected or deleted':
    'he must notify these recipients when personal data have been corrected or deleted',
  'you have the right to ask companies to whom this information was sent':
    'You have the right to ask companies to whom this information was sent',

  'however, since we do not pass on any data to third parties, this passage has no practical significance for you.':
    'However, since we do not pass on any data to third parties, this passage has no practical significance for you.',

  'right to data portability': 'Right to data portability',
  'if you have provided a company with personal data in an automated process, you have the right, in accordance with':
    'If you have provided a company with personal data in an automated process, you have the right, in accordance with',
  'art. 20 gdpr': 'Art. 20 GDPR',
  'to receive the personal data stored there about you in a structured, common and machine-readable format or to request that it be transmitted to another person responsible. at least as far as this is technically feasible.':
    'to receive the personal data stored there about you in a structured, common and machine-readable format or to request that it be transmitted to another person responsible. At least as far as this is technically feasible.',

  'in accordance with': 'In accordance with',
  'art. 21 gdpr': 'Art. 21 GDPR',
  'you have the right to object to processing of personal data at admincat at any time, unless there are compelling legitimate grounds for the processing.':
    'you have the right to object to processing of personal data at AdminCat at any time, unless there are compelling legitimate grounds for the processing.',

  'art. 22 gdpr': 'Art. 22 GDPR',

  'to exercise the right to object you can contact the relevant person responsible at any time.':
    'To exercise the right to object you can contact the relevant person responsible at any time.',

  'automated individual decision-making, including profiling':
    'Automated individual decision-making, including profiling',

  'according to': 'According to',
  'every person affected by the processing of personal data has the right not to be subject to a decision based on automated processing - including profiling. such processing is not used at admincat.':
    'Every person affected by the processing of personal data has the right not to be subject to a decision based on automated processing - including profiling. Such processing is not used at AdminCat.',

  'communication of a personal data breach to the data subject':
    'Communication of a personal data breach to the data subject',
  'if a company saves your personal data, it must inform you immediately in accordance with':
    'If a company saves your personal data, it must inform you immediately in accordance with',
  'art. 34 gdpr': 'Art. 34 GDPR',
  'if the protection of this data has been breached, we will inform you about security-relevant incidents in which the security of your data stored at admincat could be violated by email and on our website and give you tips for suitable measures.':
    'If the protection of this data has been breached, we will inform you about security-relevant incidents in which the security of your data stored at AdminCat could be violated by email and on our website and give you tips for suitable measures.',

  'right to lodge a complaint with a supervisory authority':
    'Right to lodge a complaint with a supervisory authority',
  'according to': 'According to',
  'art. 77 gdpr': 'Art. 77 GDPR',
  'you have the right to complain to a supervisory authority. the responsible supervisory authorities are':
    'you have the right to complain to a supervisory authority. The responsible supervisory authorities are',

  'affected persons have the right to complain to the competent technical supervisory authority in the event of data protection problems. the appropriate authority for us is':
    'Affected persons have the right to complain to the competent technical supervisory authority in the event of data protection problems. The appropriate authority for us is',

  'topicality and changes to this privacy statement':
    'Topicality and changes to this privacy statement',

  'this privacy statement is currently valid and is dated june 2024. due to the further development of admincat or due to changed legal or official requirements, it may be necessary to change this privacy statement. in this case, we will inform our users in advance by email.':
    'This privacy statement is currently valid and is dated June 2024. Due to the further development of AdminCat or due to changed legal or official requirements, it may be necessary to change this privacy statement. In this case, we will inform our users in advance by email.',

  'terms and conditions': 'Terms and Conditions',
  'scope and changes to these terms of use':
    'Scope and changes to these terms of use',

  'these terms of use regulate the service utilization based relationship between the customer and admincat gmbh (hereinafter "admincat" or "we"), landgraf-karl-str. 21a, 34131 kassel, germany (e-mail:':
    'These terms of use regulate the service utilization based relationship between the customer and AdminCat GmbH (hereinafter "AdminCat" or "we"), Landgraf-Karl-Str. 21A, 34131 Kassel, Germany (E-mail:',
  'admin@admincat.net': 'admin@admincat.net',
  'as a provider of e-mail, data and document storage services.':
    'as a provider of E-mail, data and document storage services.',

  'admincat reserves the right to change these terms of use as well as the service description and prices. admincat shall inform the customer of any changes. if changes are made to the detriment of the customer, he may terminate the user relationship without notice, close his account and have the remaining prepaid amount refunded. admincat informs the customer of this right of termination in the notification of change.':
    'AdminCat reserves the right to change these terms of use as well as the service description and prices. AdminCat shall inform the customer of any changes. If changes are made to the detriment of the customer, he may terminate the user relationship without notice, close his account and have the remaining prepaid amount refunded. AdminCat informs the customer of this right of termination in the notification of change.',

  'usage relationship': 'Usage relationship',

  'admincat is an online platform that provides e-mail, document storage and data processing services. the contract for the use of admincat is established by an order from the customer in the form of an online registration, for which only an existing e-mail address is required.':
    'AdminCat is an online platform that provides E-mail, document storage and data processing services. The contract for the use of AdminCat is established by an order from the customer in the form of an online registration, for which only an existing e-mail address is required.',

  'donut chart': 'Donut Chart',
  'after the free trial period of three months, the user can decide to continue using admincat by paying a monthly or yearly subscription fee for the service. if the user forgoes the option to subscribe during the trial period, he or she will no longer have access to any spaces, but will be kept as an inactive space member as long as there are other paying members assigned to that space, and the user continues to be able to subscribe and return to actively use admincat at a later point in time. however, if such an inactive user isn’t assigned to any space anymore, he or she will get deleted automatically after a grace period of three months subsequent to the expiration of the last payment period. the user will receive alert notifications via e-mail before accordingly.':
    'After the free trial period of three months, the user can decide to continue using AdminCat by paying a monthly or yearly subscription fee for the service. If the user forgoes the option to subscribe during the trial period, he or she will no longer have access to any Spaces, but will be kept as an inactive Space member as long as there are other paying members assigned to that Space, and the user continues to be able to subscribe and return to actively use AdminCat at a later point in time. However, if such an inactive user isn’t assigned to any Space anymore, he or she will get deleted automatically after a grace period of three months subsequent to the expiration of the last payment period. The user will receive alert notifications via e-mail before accordingly.',

  services: 'Services',

  'while (and even after) the registration, the customer may create a space, that includes access to a same name e-mailbox. every user is free to create up to three spaces at a time. the creator of a space automatically becomes its first administrator.':
    'While (and even after) the registration, the customer may create a Space, that includes access to a same name e-mailbox. Every user is free to create up to three Spaces at a time. The creator of a Space automatically becomes its first administrator.',

  'alternatively, a user can join another already existing Space during (and also after) registration, provided that the administrator of this space approves this new membership request.':
    'Alternatively, a user can join another already existing Space during (and also after) registration, provided that the administrator of this Space approves this new membership request.',

  'after the establishment of a space or the admission to another, the following services are available to the customer':
    'After the establishment of a Space or the admission to another, the following services are available to the customer',

  'one or, if several users have access, a common space e-mail address in the format':
    'One or, if several users have access, a common space e-mail address in the format',
  'desiredname@admincat.net': 'desiredname@admincat.net',

  'you can create a total of three spaces (including e-mail addresses) yourself':
    'You can create a total of three spaces (including e-mail addresses) yourself',
  'to join other spaces is limitless (of course only with the approval of the individual administrators)':
    'To join other spaces is limitless (of course only with the approval of the individual administrators)',
  'each space provides 1 gb storage capacity for messages, data and documents':
    'Each space provides 1 GB storage capacity for messages, data and documents',
  'sending and receiving of e-mails with a total size of up to 20 mb':
    'Sending and receiving of e-mails with a total size of up to 20 MB',
  'upload of individual documents with a size of up to 10 mb and multiple documents at once with a':
    'Upload of individual documents with a size of up to 10 MB and multiple documents at once with a',
  'size of together up to 50 mb': 'size of together up to 50 MB',

  'spam filter: e-mails suspected of being spam are not accepted by our system (reject)':
    'Spam filter: e-mails suspected of being spam are not accepted by our system (reject)',
  'virus filter: incoming e-mails that contain a virus are deleted and not delivered to the customer':
    'Virus filter: Incoming e-mails that contain a virus are deleted and not delivered to the customer',

  'if the storage space of a space is full, receiving messages or storing more documents is disabled. on the space dashboard, the users can check the allocation of storage space for each space. an extension of the storage on request is currently not available.':
    'If the storage space of a Space is full, receiving messages or storing more documents is disabled. On the Space dashboard, the users can check the allocation of storage space for each Space. An extension of the storage on request is currently not available.',
  'admincat is usually available 24 hours a day. however, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.':
    'AdminCat is usually available 24 hours a day. However, in cases of force majeure we cannot guarantee the uninterrupted accessibility and availability of all services. Of course we will announce planned maintenance work related downtimes via e-mail and on our website in advance.',

  'usage fee': 'Usage fee',
  'the use of admincat is available free of charge within the three months trial period without any special service restrictions. after that period a monthly or yearly paid subscription interval may be chosen on the user settings page (go to the subscription page to see the current subscription fees).':
    'The use of AdminCat is available free of charge within the three months trial period without any special service restrictions. After that period a monthly or yearly paid subscription interval may be chosen on the user settings page (go to the subscription page to see the current subscription fees).',

  'obligations of the customer': 'Obligations of the customer',

  'the customer will keep the access password to his account secret and change it immediately or have it changed by admincat if unauthorized third parties could have gained knowledge of it.':
    'The customer will keep the access password to his account secret and change it immediately or have it changed by AdminCat if unauthorized third parties could have gained knowledge of it.',

  'the customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. this does not apply if the customer is not responsible for unauthorized use. the customer must provide evidence that he is not responsible for such use.':
    'The customer is also liable for third parties who use or have used services via his account, either authorized or unauthorized. This does not apply if the customer is not responsible for unauthorized use. The customer must provide evidence that he is not responsible for such use.',

  'the customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.':
    'The customer will not send any advertising by e-mail for commercial purposes via the mail service and will not use the mail service to send standardized e-mails to a large number of recipients.',

  'abuse and protection of minors': 'Abuse and protection of minors',
  'the customer undertakes not to misuse admincat':
    'The customer undertakes not to misuse AdminCat',

  'not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails':
    'not to send spam (no more than 100 e-mails per hour and no more than 300 e-mails',
  'per day)': 'per day)',

  'not to violate any laws (e.g. sending forbidden or harassing messages)':
    'not to violate any laws (e.g. sending forbidden or harassing messages)',

  'not to violate regulations for the protection of young people':
    'not to violate regulations for the protection of young people',

  'not to use any mechanisms, software or scripts in connection with the use of the services that could':
    'not to use any mechanisms, software or scripts in connection with the use of the services that could',
  'impair or disrupt the functioning of admincat':
    'impair or disrupt the functioning of AdminCat',

  'the customer is liable to admincat for damage caused by violating his obligations under 6.1., and releases admincat from any third party claims in this regard. this does not apply, if he is not responsible for the violation. the customer must provide evidence that he is not responsible for the violation.':
    'The customer is liable to AdminCat for damage caused by violating his obligations under 6.1., and releases AdminCat from any third party claims in this regard. This does not apply, if he is not responsible for the violation. The customer must provide evidence that he is not responsible for the violation.',
  'if the customer uses admincat contrary to the provisions of 6.1 and 5.3, the user account can get blocked by admincat without prior notice. the right to terminate the contract without notice remains unaffected.':
    'If the customer uses AdminCat contrary to the provisions of 6.1 and 5.3, the user account can get blocked by AdminCat without prior notice. The right to terminate the contract without notice remains unaffected.',

  privacy: 'Privacy',
  'admincat and the customer will comply with the statutory data protection requirements, in particular according to the telemedia act (tmg) and the federal data protection act (bdsg). inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.':
    'AdminCat and the customer will comply with the statutory data protection requirements, in particular according to the Telemedia Act (TMG) and the Federal Data Protection Act (BDSG). Inventory and usage data of the customer are only collected, processed or used insofar as this is necessary for the execution of the contractual relationship.',

  'in order to ensure the service integrity and compliance with the terms of use of admincat, in cases of reasonable suspicion of misuse admincat is entitled to determine polluters and restrict the usage of services for these customers.':
    'In order to ensure the service integrity and compliance with the Terms of Use of AdminCat, in cases of reasonable suspicion of misuse AdminCat is entitled to determine polluters and restrict the usage of services for these customers.',

  'further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the privacy policy of admincat.':
    'Further information on the type, scope and purpose of the collection, processing and use of personal and other data can be found in the Privacy policy of AdminCat.',

  'warranty and liability': 'Warranty and Liability',
  'admincat encrypts and protects the managed data and documents on the live server as far as this is possible with the current state of the art. with regular backups, we also prevent major data loss from occurring. development guidelines and internal processes make sure, that new features are thoroughly tested in a test environment and the users data isn’t get compromised by any updates. for the unlikely event of data loss however, we recommend users to keep digital copies on their local systems as a precaution.':
    'AdminCat encrypts and protects the managed data and documents on the live server as far as this is possible with the current state of the art. With regular backups, we also prevent major data loss from occurring. Development guidelines and internal processes make sure, that new features are thoroughly tested in a test environment and the users data isn’t get compromised by any updates. For the unlikely event of data loss however, we recommend users to keep digital copies on their local systems as a precaution.',
  'admincat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. this applies in particular if access to these services is caused by disruptions that are beyond the control of admincat. we will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.':
    'AdminCat does not guarantee that all of the services mentioned under item 3 are available and free of errors at all times. This applies in particular if access to these services is caused by disruptions that are beyond the control of AdminCat. We will immediately eliminate disruptions to the services mentioned under item 3 within the scope of the existing technical and operational possibilities.',
  'any liability claim against us related to a possible data loss suffered or incorrect and/or harmful data processing needs to be documented or reasonably reproducible. our support team is your first level contact, ready to analyze incidents, give advice or support with quick bug-fixing in case we identify design and processing errors in our responsibility.':
    'Any liability claim against us related to a possible data loss suffered or incorrect and/or harmful data processing needs to be documented or reasonably reproducible. Our support team is your first level contact, ready to analyze incidents, give advice or support with quick bug-fixing in case we identify design and processing errors in our responsibility.',

  'termination, deletion of the account': 'Termination, deletion of the account',
  'the usage relationship can run for an indefinite period in principle, as long as the user subscribed and paid for the service in advance. it can actively get terminated by the customer at any time – not via email, but by deleting the user account directly under the user settings, in which case we unfortunately can’t provide a reimbursement for the remaining prepaid subscription. the right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.':
    'The usage relationship can run for an indefinite period in principle, as long as the user subscribed and paid for the service in advance. It can actively get terminated by the customer at any time – not via email, but by deleting the user account directly under the user settings, in which case we unfortunately can’t provide a reimbursement for the remaining prepaid subscription. The right to terminate the contract without notice in accordance with section 1.2 or for an important reason (sections 5 and 6) remains unaffected.',
  'a deletion removes all sensitive details of a user, such as email, password, its space memberships and payments. however, for technical reasons, user id and the freely defined user name will remain in the system. inactive user accounts, whose subscription ended more than three months ago and which are also no longer members of a space anymore will get deleted automatically by admincat.':
    'A deletion removes all sensitive details of a user, such as email, password, its Space memberships and payments. However, for technical reasons, user id and the freely defined user name will remain in the system. Inactive user accounts, whose subscription ended more than three months ago and which are also no longer members of a Space anymore will get deleted automatically by AdminCat.',
  'if the subscription of a space admin expires, the admin role gets reassigned to any of the remaining active/paying users (if any) automatically, such that the space continues to have an admin. if space administrating users decide to actively delete themselves and the admin role was not consciously transferred to another user before, the admin role gets reassigned to any of the remaining active/paying (or in secondary priority inactive/non-paying) users automatically. if there is none or the payment periods of all relevant inactive users ended more than 3 months ago, the space will get deleted automatically as well.':
    'If the subscription of a Space admin expires, the admin role gets reassigned to any of the remaining active/paying users (if any) automatically, such that the Space continues to have an admin. If Space administrating users decide to actively delete themselves and the admin role was not consciously transferred to another user before, the admin role gets reassigned to any of the remaining active/paying (or in secondary priority inactive/non-paying) users automatically. If there is none or the payment periods of all relevant inactive users ended more than 3 months ago, the Space will get deleted automatically as well.',

  'governing law': 'Governing Law',
  'the law of the federal republic of germany applies exclusively to all legal relationships between admincat and the customer. the law on the united nations convention on the international sale of goods ("cisg") does not apply.':
    'The law of the Federal Republic of Germany applies exclusively to all legal relationships between AdminCat and the customer. The law on the United Nations Convention on the International Sale of Goods ("CISG") does not apply.',

  'status: june 2024': 'Status: June 2024',

  'heads-up period (in weeks) for documents and contracts':
    'Heads-up period (in weeks) for documents and contracts',

  'request date': 'Request date',
  'decision date': 'Decision date',
  approve: 'Approve',
  disapprove: 'Disapprove',
  issuer: 'Issuer',
  decline: 'Decline',
  'work experience': 'Work Experience',
  'choose organisation': 'Choose Organisation',
  'choose profession': 'Choose Profession',
  'title description': 'Title description',
  'work experience updated successfully': 'Work Experience updated successfully',
  'you are currently on the free trial period plan - still for ':
    'You are currently on the free trial period plan - still for ',
  'you are currently on the subscription plan - still for ':
    'You are currently on the subscription plan - still for ',
  ' days': ' days',
  'the contract': 'The contract',
  'can get cancelled until': 'can get cancelled until',
  'if you pass this date, the contract will get extended automatically':
    'If you pass this date, the contract will get extended automatically',
  'space currency amount': 'Space Currency Amount',
  'space amount': 'Space Amount',
  transaction: 'Transaction',
  'contracts (cost per month)': 'Contracts (cost per month)',
  'income vs expenditure': 'Income vs Expenditure',
};

// The Unauthenticated area
// This is only meant for the Login , Register and Footer Page ,, Forgot Password;
const en = {
  translations: {
    'I Agree With the Terms and Conditions':
      ' I Agree With the Terms and Conditions',
    Username: 'Username',
    'Personal User': 'Personal User',
    'Already have an account?': 'Already have an account?',
    Register: 'Register',
    Login: 'Login',
    Password: 'Password',
    'Forgot Password': 'Forgot Password',
    Copyright: 'Copyright',
    'All rights reserved': 'All rights reserved',
    'Privacy Policy': 'Privacy Policy',
    link: 'eng/privacy-statement',
    'sign in': 'Sign in',
    'join personal workspace': 'Join Personal Workspace',
    'create personal workspace': 'Create Personal Workspace',
    'Organisation User': 'Organisation User',
    'Password Reset': 'Password Reset',
    'All You Need is in one App': 'All you need in one App!',
    'Change My Password': 'Change My Password',
    'New Password': 'New Password',
    'Confirm Password': 'Confirm Password',
    faqs: 'FAQs',
    'user terms': 'User Terms',
  },
};

export default en;
