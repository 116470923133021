// /* eslint-disable */
import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { withMsgInfo, withTranslation, withAccount } from '../hoc';
import {
  ExclamationTriangle,
  Check2Circle,
  ExclamationCircle,
  ExclamationOctagon,
} from 'react-bootstrap-icons';
import SubscriptionAlert from 'components/SubscriptionAlert';

const Container = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 500;
  width: 100%;
  box-shadow: 0 1px 1px 0 rgba(3, 3, 3, 0.05);
  // background-color: var(--admincat-color-grey-0);
  background: ${({ isAuthenticated }) =>
    isAuthenticated ? 'var(--admincat-color-grey-4)' : '#f5f7fb'};
  position: sticky;
  top: 80px;
`;

const NotificationBar = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  margin-left: 80px;
  // background: var(--admincat-color-grey-4);
  // background: ${({ isAuthenticated }) =>
    isAuthenticated ? 'var(--admincat-color-grey-4)' : '#f5f7fb'};
  padding: 0.5em 0.5em;
  z-index: 1000;
  height: 50px; // adjust the height here

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 80px;
  }
`;

const MessageContent = styled.div`
  display: flex;
  align-items: center;
  width: 49%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MessageText = styled.p`
  margin: 0;
  padding: 0;
  font-size: clamp(14px, 4vw, 14px);
  ${({ type }) => typeStyles[type]}
`;

const Icon = styled.span`
  margin-right: 10px;
  ${({ type }) => css`
    color: ${typeStyles[type].color};
  `}
`;

const Banner = styled.div`
  color: #4f81bd;
  text-align: left;
  width: 100%;
  font-size: clamp(14px, 4vw, 14px);
  margin-left: 5px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-top: 20px;
  }
`;

const SpanDivider = styled.span`
  color: #948d8d;
`;

const SpanText = styled.span`
  color: #e00a0a;
`;

const SubscribeButton = styled.a`
  background-color: #c24611;
  border-radius: 25px;
  padding: 5px 5px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 30px;
  font-size: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;

const DANGER_COLOR = '#f60a0a';
const SUCCESS_COLOR = '#439f6e';
const INFO_COLOR = '#3498db';
const WARNING_COLOR = '#ffa500';

const typeStyles = {
  success: css`
    color: ${SUCCESS_COLOR};
  `,
  warning: css`
    color: ${WARNING_COLOR};
  `,
  info: css`
    color: ${INFO_COLOR};
  `,
  danger: css`
    color: ${DANGER_COLOR};
  `,
};

class MsgInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: [],
      success: false,
      info: false,
      warning: false,
      danger: false,
      show: false,
      showPaymentBanner: false,
      navigationCounter: 0,
    };
  }

  componentDidUpdate(prevProps) {
    // Compare the relevant prop to see if it changed
    if (this.props.msgInfo.msg !== prevProps.msgInfo.msg) {
      if (this.state.navigationCounter >= 0) {
        // at this point we need to determine
        // if we want the current content in the navigation
        // to persist.

        let shouldOveride = this.props.msgInfo?.msg.length > 0;
        if (shouldOveride) {
          this.setData({ ...this.props.msgInfo, show: true });
        } else if (!this.state.show && this.state.navigationCounter == 0) {
          let isTrialPeriodLeft = this.isTrialPeriodLeft();

          this.setData({
            msg: [],
            navigationCounter: 0,
            success: false,
            show: isTrialPeriodLeft || false,
          });
        }
      } else {
        this.setData({ ...this.props.msgInfo, show: true });
      }
    }

    // this condition is expected to execute
    // only on the initial update of the component
    // when the value of trial period moves from
    // undefined to defined
    if (
      prevProps?.account?.user?.trial_period_left == undefined &&
      this.props?.account?.user?.trial_period_left != undefined
    ) {
      if (this.props.account?.user?.trial_period_left > 0) {
        this.setState({ show: true });
      }
    }

    // this condition ensures that if the trial period is left
    // the menu bar should always be visible
    if (!this.state.show && this.isTrialPeriodLeft()) {
      this.setState({ show: true });
    }
  }

  setData(data) {
    let {
      success,
      msg,
      navigationCounter,
      show,
      info,
      warning,
      danger,
      showPaymentBanner,
    } = data;
    // Set navigation counter based on message type
    navigationCounter = navigationCounter || (success ? 2 : 1);

    if (this.isTrialPeriodLeft()) {
      show = true;
    } else {
      show = show || false;
    }

    this.setState({
      success,
      msg,
      navigationCounter,
      show,
      info: info || false,
      warning: warning || false,
      danger: danger || false,
      showPaymentBanner: true,
    });
  }

  getTrialPeriodFromProps() {
    return this.props?.account?.user?.trial_period_left;
  }

  isTrialPeriodLeft() {
    const trialPeriodLeft = this.getTrialPeriodFromProps();
    return typeof trialPeriodLeft === 'number' && trialPeriodLeft > 0;
  }

  componentDidMount() {
    var trialPeriodLeft = this.isTrialPeriodLeft();
    if (this.props.history) {
      this.unlisten = this.props.history.listen(() => {
        if (this.state.navigationCounter > 0) {
          if (trialPeriodLeft) {
            this.setState((prevState) => ({
              navigationCounter: prevState.navigationCounter - 1,
              show: true,
            }));
          } else {
            this.setState((prevState) => ({
              navigationCounter: prevState.navigationCounter - 1,
              show: prevState.navigationCounter > 1,
            }));
          }
        } else {
          this.setState({
            navigationCounter: 0,
            show: trialPeriodLeft,
            success: false,
            msg: [],
          });
        }
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.unlisten === 'function') {
      this.unlisten();
    }
  }

  render() {
    const { tr, account, daysLeftUntilSubscriptionEnds } = this.props;
    const trialPeriod = account?.user?.trial_period_left;
    let { success, warning, danger, info, msg, showPaymentBanner } = this.state;

    const user = account?.user;
    const isValidUser = user && Object.keys(user).length > 0;

    let type = 'danger';

    if (success) {
      type = 'success';
    } else if (warning) {
      type = 'warning';
    } else if (info) {
      type = 'info';
    } else if (danger) {
      type = 'danger';
    }

    const icon =
      type === 'success' ? (
        <Check2Circle color={SUCCESS_COLOR} />
      ) : type === 'warning' ? (
        <ExclamationOctagon color={WARNING_COLOR} />
      ) : type === 'info' ? (
        <ExclamationCircle color={INFO_COLOR} />
      ) : (
        <ExclamationTriangle color={DANGER_COLOR} />
      );

    const isAuthenticated = account?.user?.isAuth;

    return (
      <Container
        show={this.state.show}
        type={type}
        isAuthenticated={isAuthenticated}
      >
        <NotificationBar show={this.state.show} type={type}>
          <MessageContent>
            {this.state.showPaymentBanner && (
              <Banner>
                {daysLeftUntilSubscriptionEnds <= 1 ? (
                  tr('Your subscription ends today')
                ) : (
                  <>
                    {tr('You are currently on the subscription plan - still for ')}
                    <SpanText>{`${daysLeftUntilSubscriptionEnds}`}</SpanText>
                    <SpanText>{tr(' days')}</SpanText>
                  </>
                )}
                <SubscribeButton href={`/dashboard/settings/payments/create`}>
                  {tr('Subscribe')}
                </SubscribeButton>
              </Banner>
            )}
            {msg.length > 0 && !this.state.showPaymentBanner && (
              <>
                <Icon type={type}>{icon}</Icon>
                <MessageText type={type}>{tr(msg[0])}</MessageText>
              </>
            )}
          </MessageContent>
          {isValidUser && <SubscriptionAlert daysLeft={trialPeriod} tr={tr} />}
        </NotificationBar>
      </Container>
    );
  }
}

export default compose(
  withRouter,
  withMsgInfo,
  withTranslation,
  withAccount,
)(MsgInfo);
